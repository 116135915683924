import api from './init';
import { getCurrentToken } from '../helpers/users';

export function sendMassage(title, message, to) {
  return api
    .post(
      '/mailer',
      { title, message, to },
      { headers: { Authorization: `Bearer ${getCurrentToken()}` } }
    )
    .then(res => res.data);
}
