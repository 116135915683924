import styled, { css } from 'styled-components';

const Dropdown = styled.div`
  position: relative;
  margin-bottom: 10px;
  width: 115px;

  & + & {
    margin-left: 10px;
  }
`;

const DropdownLabel = styled.span`
  position: absolute;
  right: calc(100% + 5px);
  top: 0;
`;

const DropdownTrigger = styled.div`
  position: relative;
  padding: 8px 30px 8px 10px;
  border: 2px solid ${props => props.theme.colors.lightGray};
  background: #fff;
  border-radius: ${props => props.theme.borderRadius};
  display: flex;
  flex-flow: row nowrap;

  .trigger-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover,
  &:focus {
    border-color: ${props => props.theme.colors.primary};
  }

  .trigger-chevron {
    position: absolute;
    display: inline-block;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    transition: 0.2s;
    transform-origin: center 25%;
  }

  ${props =>
    props.dropdownOpened &&
    css`
      border-color: ${props.theme.colors.primary};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 0;

      .trigger-chevron {
        transform: rotate(180deg);
      }
    `}

  .checked-count {
    display: inline-flex;
    align-content: center;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    line-height: 1;
    margin-left: 5px;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.colors.primary};
  }
`;

const DropdownContent = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  display: none;
  left: 0;
  /* top: 100%; */
  right: 0;
  max-height: 200px;
  padding: 10px;
  background: #fff;
  z-index: 11;
  border: 2px solid ${props => props.theme.colors.primary};
  border-top: 0;
  border-radius: 0 0 ${props => props.theme.borderRadius}
    ${props => props.theme.borderRadius};

  ${props =>
    props.dropdownOpened &&
    css`
      display: block;
    `}
`;

export { Dropdown, DropdownLabel, DropdownTrigger, DropdownContent };
