import React from 'react';
import Layout from '../../layouts/Layout/Layout';
import * as Styled from './Styleguide.styles';
import Box from '../../layouts/Box';
import Button from '../../components/Button';
import { BoxHeader } from '../../layouts/Box/Box.styles';
import AlertBlock from '../../components/AlertBlock';
import Dropdown from '../../components/Dropdown';

const Styleguide = props => {
  const { currentUser, navToggler } = props;
  return (
    <Layout currentUser={currentUser} navToggler={navToggler}>
      <Styled.Styleguide>
        <Box>
          <BoxHeader>Alerts</BoxHeader>
          <AlertBlock info icon="info">
            Alert - Info
          </AlertBlock>
          <AlertBlock warning icon="warning">
            Alert - Warning
          </AlertBlock>
          <AlertBlock danger icon="danger">
            Alert - Danger
          </AlertBlock>
          <AlertBlock success icon="success">
            Alert - Success
          </AlertBlock>
          <AlertBlock info large icon="info">
            Alert - Info Large
          </AlertBlock>
          <AlertBlock inline danger icon="danger">
            Alert - Inline Danger
          </AlertBlock>
        </Box>

        <Box>
          <BoxHeader>Buttons</BoxHeader>
          <Button alert text="Button alert" />
        </Box>

        <Box>
          <BoxHeader>Dropdown</BoxHeader>

          <Dropdown header="Trigger">Dropdown content</Dropdown>
        </Box>
      </Styled.Styleguide>
    </Layout>
  );
};

export default Styleguide;
