import React from 'react';
import { Field } from 'formik';
import * as Styled from './InputWrapper.styles';

const InputWrapper = ({
  className,
  name,
  type,
  as,
  label,
  error,
  touched,
  value,
  children,
  component,
  disabled
}) => (
  <Styled.InputWrapper
    className={`${className || ''} ${error && touched ? ' filed-error' : ''}${
      !error && touched && value !== false && value !== ''
        ? ' filed-success'
        : ''
    }`}
  >
    <Field
      name={name}
      id={name}
      type={type}
      as={as}
      placeholder={name}
      component={component}
      disabled={disabled}
    >
      {children}
    </Field>
    {label && (
      <Styled.FieldLabel htmlFor={name}>
        <span>{label}</span>
      </Styled.FieldLabel>
    )}
    {error && touched && (
      <Styled.FieldMsg role="alert">{error}</Styled.FieldMsg>
    )}
  </Styled.InputWrapper>
);

export default InputWrapper;
