import styled from 'styled-components';
import ReactModal from 'react-modal';

const BookingModal = styled(ReactModal)`
  position: fixed;
  z-index: 9999;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  outline: none;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    padding: 40px 20px 20px;
    height: 100%;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 40px;
    border-radius: ${props => props.theme.borderRadius};
    max-width: 60%;
    margin-top: 30px;
    //transform: translate(0, -50px);
  }
  
  &.ReactModal__Content {
    opacity: 0;
    transform: translateY(100px);
    transition: all 500ms ease-in-out;
    
    &--after-open {
      opacity: 1;
      transform: translateY(0);
    }
    
    &--before-close {
      opacity: 0;
      transform: translateY(100px);
    }
  }

  // &.ReactModal__Content--after-open {
  //   @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
  //     transition: transform 0.3s ease-out;
  //     transform: translate(0, 0);
  //   }
  // }

  .modal-title {
    margin-bottom: 24px;
  }

  .modal-body {
    margin-bottom: 40px;
  }

  .modal-body-head {
    margin-bottom: 18px;
  }

  .modal-btn-box {
    display: flex;
    flex-direction: column;
  }

  .modal-contact-link {
    margin-bottom: 20px;
  }

  .modal-close-btn {
    position: absolute;
    top: 3px;
    right: 0;
  }
`;

export { BookingModal };
