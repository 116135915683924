import styled from 'styled-components';
import themes from '../../config/theme';

const SpinnerLayer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const SpinnerWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: ${themes.base.borderRadius};
  background-color: rgba(235, 241, 245, 0.7);
`;

const Spinner = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid ${props => props.theme.colors.white};
  border-top-color: ${themes.base.colors.primary};
  animation: spinner 0.6s linear infinite;

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .text {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
`;

export { Spinner, SpinnerWrapper, SpinnerLayer };
