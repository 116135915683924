import moment from 'moment';

export const displayHourOptions = (calendarDate, currentDate, end = false) => {
  const options = [];
  const now = currentDate ? new Date(currentDate) : new Date();
  const nowHours = now.getHours();
  let nowMinutes = now.getMinutes();
  const calendar = new Date(calendarDate);
  let hourStart = 7;
  const hourEnd = 21;
  const isToday =
    moment(now).format('Y M D') === moment(calendar).format('Y M D');

  hourStart =
    isToday && nowHours > hourStart && nowHours < hourEnd
      ? nowHours
      : hourStart;

  if (end) {
    const endArray = end.split(':');

    if (endArray[0] > hourStart) {
      hourStart = parseInt(endArray[0], 10);
      nowMinutes = parseInt(endArray[1], 10);
    }
  }

  for (let i = hourStart; i < hourEnd; i += 1) {
    if (end === false) {
      if (i !== hourStart || !isToday) options.push(`${i}:00`);
      if (!(i === hourStart && nowMinutes > 30) || !isToday)
        options.push(`${i}:30`);
    } else {
      if (i !== hourStart || (end && !end.includes('30')))
        options.push(`${i}:30`);
      if (!(i === hourStart && nowMinutes > 30)) options.push(`${i + 1}:00`);
    }
  }

  return options;
};

// formats the time extracted from the time inputs into an array, eg 8:30 => [8, 30]
export const formatTime = time => {
  let formatedTimeArray = [];
  formatedTimeArray = time.split(':').map(item => parseInt(item, 10));
  return formatedTimeArray;
};
