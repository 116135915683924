import styled from 'styled-components';

const TopbarFilters = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export { TopbarFilters };
