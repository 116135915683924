export const floorParams = [
  { name: 'Salki', value: '1' },
  { name: 'Hotdeski', value: '2' },
  { name: 'Miejsca garażowe', value: '3' },
  { name: 'Wszystkie', value: '' }
];

export const featureParams = [
  { name: 'TV', value: 'tv' },
  // { name: 'iMac', value: 'mac30' },
  { name: 'Tablica', value: 'blackboard' },
  { name: 'Dwa monitory', value: 'two-monitors' },
  { name: 'Apple TV', value: 'apple-tv' }
];

export const capacityParams = [
  // { name: '1 osoba', value: 1 },
  { name: '3 osoby', value: 3 },
  { name: '5 osób', value: 5 },
  { name: '6 osób', value: 6 },
  { name: '8 osób', value: 8 },
  { name: '10 osób', value: 10 }
];

//
// Filtering Functions
//

// Filter roomData by floor
export const onFilterByFloor = (param, filteredData) => {
  return param === ''
    ? filteredData
    : filteredData.filter(room => room.floor === param);
};

// Filter data by feature
export const onFilterByFeature = (params, filteredData) => {
  return params.length
    ? filteredData.filter(room =>
        params.every(
          element => room.assets && room.assets.indexOf(element) > -1
        )
      )
    : filteredData;
};

export const onFilterRoomsByFeature = (params, filteredData) => {
  const skippedRooms = filteredData.filter(
    room => parseInt(room.floor, 10) === 2 || parseInt(room.floor, 10) === 3
  );

  const filteredRooms = params.length
    ? filteredData.filter(
        room =>
          parseInt(room.floor, 10) === 1 &&
          params.every(
            element => room.assets && room.assets.indexOf(element) > -1
          )
      )
    : [];

  const responseData = filteredRooms.concat(skippedRooms);

  return params.length ? responseData : filteredData;
};

// Filter data by capacity
export const onFilterByCapacity = (params, filteredData) => {
  return params.length
    ? filteredData.filter(
        room =>
          parseInt(room.floor, 10) === 2 ||
          parseInt(room.floor, 10) === 3 ||
          params.includes(room.capacity)
      )
    : filteredData;
};
