import React from 'react';
import { Link } from 'react-router-dom';
import * as Styled from './ConferenceRoomsView.styles';
import BookingTooltip from '../BookingTooltip';

const ConferenceRoomsView = props => {
  const {
    rooms,
    onSetRoom,
    bookings,
    setSelectedBooking,
    calendarDate,
    setCurrentHour,
    currentUser
  } = props;

  return (
    <Styled.ConferenceRoomsView>
      <div className="floor">
        <div className="room main">
          korytarz
          <br />
          windy
          <span className="door" />
        </div>

        <div className="room reception">recepcja</div>

        {rooms.map(room => {
          if (room.disabled)
            return (
              <div
                className={`room disabled ${room.name
                  .replace(/\s+/g, '-')
                  .toLowerCase()}`}
                key={room.name}
              >
                <div className="room-content">
                  <span className="door" />
                  {room.name}
                </div>
              </div>
            );

          return (
            <div
              className={`room ${room.name.replace(/\s+/g, '-').toLowerCase()}`}
              key={room.name}
            >
              <Link
                title="Zarezerwuj salkę"
                to="/createbooking"
                onClick={() => {
                  onSetRoom(room._id);
                }}
                className="room-content"
              >
                <span className="door" />
                {room.name}
                {room.features.length > 0
                  ? room.features.map(feature => (
                      <span
                        className={`feature-icon ${feature.key}`}
                        key={feature.key}
                      />
                    ))
                  : null}
              </Link>

              <BookingTooltip
                room={room}
                bookings={bookings}
                setSelectedBooking={setSelectedBooking}
                calendarDate={calendarDate}
                onSetRoom={onSetRoom}
                setCurrentHour={setCurrentHour}
                currentUser={currentUser}
              />
            </div>
          );
        })}
      </div>
    </Styled.ConferenceRoomsView>
  );
};

export default ConferenceRoomsView;
