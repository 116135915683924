export const roles = {
  worker: 0,
  hr: 0,
  ceo: 90,
  admin: 99
};

export const userRoles = {
  admin: [
    'wiktoria.gregorczyk@plej.pl',
    'lukasz.piwowarczyk@plej.pl',
    'andrzej.wilczynski@plej.pl'
  ],
  ceo: [
    'mariusz.graca@plej.pl',
    'lukasz.ostaszewski@plej.pl',
    'lukasz.gumowski@plej.pl',
    'rafal.krawczuk@plej.pl'
  ]
};
