import React from 'react';
import { Link } from 'react-router-dom';
import { dailyBookings, bookingArray } from '../../../helpers/rooms';
import { userUnitKey } from '../../../helpers/users';
import * as Styled from './ColumnCell.styles';

const ColumnCell = props => {
  // Get the day's bookings for a room
  const bookings = dailyBookings(props.calendarDate, props.bookings);

  // Add the day's bookings to a 24 hour array
  const dayHours = bookingArray(bookings);

  // Extract the corresponding data for a single hour from the 24 hour array
  const bookingData = dayHours[props.hour];

  // Data to be returned
  let columnData;

  // If the data for that hour is a number (not a booking object), there is no booking
  // Return a <td> element that indicates the time slot is available
  if (typeof bookingData === 'number') {
    columnData = (
      <Styled.ColumnCell>
        {props.onSetRoom && (
          <Link
            title="Zarezerwuj ten termin"
            to="/createbooking"
            onClick={() => {
              props.onSetRoom(props.room._id);
              props.setCurrentHour(`${props.hour}:00`);
            }}
            className="table__link--available"
          />
        )}
      </Styled.ColumnCell>
    );

    // If the data for that hour is an array, this means there are two bookings to be rendered
  } else if (Array.isArray(bookingData)) {
    // Determine which of the two bookings comes first and second
    const firstBookingData = bookingData[0].firstHalfHour
      ? bookingData[0]
      : bookingData[1];

    const secondBookingData = bookingData[0].secondHalfHour
      ? bookingData[0]
      : bookingData[1];

    columnData = (
      <Styled.ColumnCell>
        <button
          title={`Zarezerwowany przez: ${firstBookingData.name}`}
          aria-label="Rezerwacja"
          onClick={() => props.setSelectedBooking(firstBookingData)}
          type="button"
          className={`
            table__cell--booked table__cell--half table__cell--first-half-hour
            table__cell--${userUnitKey(firstBookingData.businessUnit)}
            ${firstBookingData.duration === 0.5 ? 'table__cell--start' : ''}
            ${
              props.currentUser &&
              firstBookingData.user === props.currentUser.id
                ? 'my__booking'
                : ''
            }
            ${firstBookingData.status === 1 ? 'status--pending' : ''}
          `}
        />
        <button
          title={`Zarezerwowany przez: ${secondBookingData.name}`}
          aria-label="Rezerwacja"
          onClick={() => props.setSelectedBooking(secondBookingData)}
          type="button"
          className={`
            table__cell--booked table__cell--half table__cell--second-half-hour
            table__cell--${userUnitKey(secondBookingData.businessUnit)}
            ${secondBookingData.duration === 0.5 ? 'table__cell--end' : ''}
            ${
              props.currentUser &&
              secondBookingData.user === props.currentUser.id
                ? 'my__booking'
                : ''
            }
            ${secondBookingData.status === 1 ? 'status--pending' : ''}
          `}
        />
      </Styled.ColumnCell>
    );

    // If there is a booking object, but only for the first half of the hour, return a nested table to split the table data for that cell into two rows.
  } else if (bookingData.firstHalfHour || bookingData.secondHalfHour) {
    columnData = (
      <Styled.ColumnCell>
        <button
          title={`Zarezerwowany przez: ${bookingData.name}`}
          aria-label="Rezerwacja"
          onClick={() => props.setSelectedBooking(bookingData)}
          type="button"
          className={`table__cell--booked table__cell--${userUnitKey(
            bookingData.businessUnit
          )} 
            ${
              bookingData.firstHalfHour
                ? 'table__cell--half table__cell--first-half-hour'
                : ''
            }
            ${
              bookingData.secondHalfHour
                ? 'table__cell--half table__cell--second-half-hour'
                : ''
            }
            ${props.hour === bookingData.startHour ? 'table__cell--start' : ''}
            ${
              props.hour === bookingData.startHour + bookingData.duration - 1
                ? 'table__cell--end'
                : ''
            }
            ${
              props.currentUser && bookingData.user === props.currentUser.id
                ? 'my__booking'
                : ''
            }
            ${bookingData.status === 1 ? 'status--pending' : ''}
          `}
        />
        {props.onSetRoom ? (
          <Link
            title="Zarezerwuj ten termin"
            to="/createbooking"
            onClick={() => {
              props.onSetRoom(props.room._id);
              props.setCurrentHour(
                props.hour + (bookingData.secondHalfHour ? ':00' : ':30')
              );
            }}
            className={`
                table__link--available
                ${
                  bookingData.secondHalfHour
                    ? 'table__cell--half table__cell--first-half-hour'
                    : ''
                }
                ${
                  bookingData.firstHalfHour
                    ? 'table__cell--half table__cell--second-half-hour'
                    : ''
                }
              `}
          />
        ) : (
          <span
            className={`table__cell--available
              ${
                bookingData.secondHalfHour
                  ? 'table__cell--half table__cell--first-half-hour'
                  : ''
              }
              ${
                bookingData.firstHalfHour
                  ? 'table__cell--half table__cell--second-half-hour'
                  : ''
              }
            `}
          />
        )}
      </Styled.ColumnCell>
    );

    // If there is a booking object, but only for the second half of the hour, return a nested table to split the table data for that cell into two rows
  } else {
    columnData = (
      <Styled.ColumnCell>
        <button
          title={`Zarezerwowany przez: ${bookingData.name}`}
          aria-label="Rezerwacja"
          onClick={() => props.setSelectedBooking(bookingData)}
          type="button"
          className={`table__cell--booked table__cell--${userUnitKey(
            bookingData.businessUnit
          )}
            ${
              bookingData.firstHalfHour
                ? 'table__cell--half table__cell--first-half-hour'
                : ''
            }
            ${
              bookingData.secondHalfHour
                ? 'table__cell--half table__cell--second-half-hour'
                : ''
            }
            ${props.hour === bookingData.startHour ? 'table__cell--start' : ''}
            ${
              props.hour === bookingData.startHour + bookingData.duration - 1
                ? 'table__cell--end'
                : ''
            }
            ${
              props.currentUser && bookingData.user === props.currentUser.id
                ? 'my__booking'
                : ''
            }
            ${bookingData.status === 1 ? 'status--pending' : ''}
          `}
        />
      </Styled.ColumnCell>
    );
  }
  return columnData;
};

export default ColumnCell;
