import styled from 'styled-components';

const Authentication = styled.div`
  background: radial-gradient(at center, #3498db, #9b59b6);
  background-size: 200% 200%;
  height: 100vh;
  width: 100vw;
`;

const AuthenticationWrapper = styled.section`
  overflow: hidden;
  position: fixed;
  display: flex;
  top: 50%;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.2);

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    left: 20px;
    max-width: 100%;
    right: 20px;
    transform: translateY(-50%);
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    flex-flow: row nowrap;
    align-items: stretch;
    left: 50%;
    width: 100%;
    max-width: 1024px;
    transform: translate(-50%, -50%);
  }

  .auth-main {
    background-color: ${props => props.theme.colors.white};
    text-align: center;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      padding: 40px;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding: 80px;
      flex-grow: 1;
    }
  }

  .auth-aside {
    position: relative;
    padding: 40px;
    color: ${props => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
      width: 90px;
      margin-bottom: 20px;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 30%;
    }
  }
`;

export { Authentication, AuthenticationWrapper };
