import React from 'react';
import * as Styled from './Header.styles';
import Logo from '../Logo';
import ReservationTypeNav from '../../components/ReservationTypeNav';
import NavToggler from '../Navigation/NavToggler';

const Header = ({ navToggler }) => (
  <Styled.Header>
    <Logo anchor />
    <ReservationTypeNav />
    <NavToggler navToggler={navToggler} />
  </Styled.Header>
);

export default Header;
