import React, { useEffect, useState, useRef } from 'react';
import * as Styled from './Dropdown.styles';
import Icon from '../Icon/Icon';

const Dropdown = props => {
  const { className, labelIcon, header, children } = props;
  const node = useRef();
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) return;
    setDropdownOpened(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, []);

  return (
    <Styled.Dropdown aria-haspopup="listbox" ref={node} className={className}>
      {labelIcon && <Styled.DropdownLabel>{labelIcon}</Styled.DropdownLabel>}
      <Styled.DropdownTrigger
        onClick={() => setDropdownOpened(!dropdownOpened)}
        dropdownOpened={dropdownOpened}
      >
        {header}
        <Icon icon="chevron-down" size={12} className="trigger-chevron" />
      </Styled.DropdownTrigger>
      <Styled.DropdownContent dropdownOpened={dropdownOpened}>
        {children}
      </Styled.DropdownContent>
    </Styled.Dropdown>
  );
};

export default Dropdown;
