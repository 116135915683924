import React from 'react';
import momentTimezone from 'moment-timezone';
import { useAlert } from 'react-alert';
import { CSSTransition } from 'react-transition-group';
import * as Styled from './BookingModal.styles';
import Button from '../Button';
import { roles } from '../../config/roles';
import { H3, H4 } from '../Typography/Typography.styles';

const BookingModal = props => {
  const alert = useAlert();
  const {
    selectedBooking,
    onDeleteBooking,
    onCloseBooking,
    currentUser,
    currentDataDate
  } = props;

  const deleteBooking = () => {
    alert.info('Na pewno chcesz usunąć rezerwację?', {
      customElements: () => (
        <Button
          onClick={() => {
            alert.removeAll();
            onDeleteBooking(selectedBooking);
            onCloseBooking();
          }}
          alert
        >
          Usuń
        </Button>
      )
    });
  };

  const displayAttendees = attendees => {
    if (!attendees || attendees.length === 0) return null;

    return (
      <p>
        <strong>Uczestnicy: </strong>
        {selectedBooking.attendees.join(', ')}
      </p>
    );
  };

  return (
    <CSSTransition in={!!selectedBooking} timeout={300}>
      <Styled.BookingModal
        isOpen={!!selectedBooking}
        onRequestClose={onCloseBooking}
        ariaHideApp={false}
        shouldFocusAfterRender
        shouldReturnFocusAfterClose
        contentLabel="Booking"
        closeTimeoutMS={500}
        className="modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,.5)',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 100
          }
        }}
      >
        <H3 className="modal-title">Dane rezerwacji</H3>

        {selectedBooking && (
          <>
            <div className="modal-body">
              {selectedBooking.roomId.name && (
                <H4 className="modal-body-head">
                  {selectedBooking.roomId.name}
                </H4>
              )}
              <p>
                {`${momentTimezone
                  .tz(selectedBooking.bookingStart, 'Europe/Warsaw')
                  .format('H:mm')} -
          ${momentTimezone
            .tz(selectedBooking.bookingEnd, 'Europe/Warsaw')
            .format('H:mm, D MMMM YYYY')}`}
              </p>
              <p>
                <strong>Osoba: </strong>
                {selectedBooking.name}
              </p>
              {selectedBooking.businessUnit &&
                selectedBooking.businessUnit !== '' && (
                  <p>
                    <strong>Dział: </strong>
                    {selectedBooking.businessUnit}
                  </p>
                )}
              <p>
                <strong>Temat: </strong>
                {selectedBooking.purpose}
              </p>
              {selectedBooking.description && (
                <p>
                  <strong>Opis: </strong>
                  {selectedBooking.description}
                </p>
              )}
              {displayAttendees(selectedBooking.attendees)}
              {// if its not accepted
              selectedBooking.status !== 3 && (
                <p>
                  <strong>Status: </strong>
                  {selectedBooking.status === 1
                    ? 'Oczekująca na potwierdzenie'
                    : 'Odrzucona'}
                </p>
              )}
            </div>

            <div className="modal-btn-box">
              {selectedBooking.email &&
                currentUser.email !== selectedBooking.email && (
                  <>
                    <a
                      href={`mailto:${selectedBooking.email}`}
                      className="modal-contact-link"
                    >
                      Wyślij email
                    </a>

                    <a
                      href={`https://teams.microsoft.com/l/chat/1/0?users=${selectedBooking.email}`}
                      className="modal-contact-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Napisz na Teams
                    </a>
                  </>
                )}
              {(currentUser.role === roles.admin ||
                currentUser.email.toLowerCase() ===
                  selectedBooking.email.toLowerCase()) &&
                selectedBooking.bookingEnd >= currentDataDate && (
                  <Button
                    alert
                    onClick={deleteBooking}
                    text="Usuń rezerwację"
                  />
                )}
              <Button
                close
                onlyIcon
                className="modal-close-btn"
                onClick={onCloseBooking}
                text="Zamknij"
              />
            </div>
          </>
        )}
      </Styled.BookingModal>
    </CSSTransition>
  );
};
export default BookingModal;
