import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../layouts/Layout/Layout';
import Table from '../../components/Typography/Table';
import PendingBookingRow from './PendingBookingRow';
import {
  updateBookingStatus,
  updateBookingStateOnUpdate,
  getBooking,
  listBookings
} from '../../api/booking';
import { sendMassage } from '../../api/mailer';
import * as Styled from './PendingBookings.styles';
import { roles } from '../../config/roles';

const PendingBookings = props => {
  const { bookingId, bookingStatus } = useParams();
  const [pendingBookings, setPendingBookings] = useState([]);
  const {
    bookingData,
    setBookingData,
    currentUser,
    navToggler,
    currentDataDate,
    setCurrentDataDate
  } = props;

  const filterBookings = (bookings, date) => {
    const pending = bookings
      ? bookings.filter(
          booking => booking.status === 1 && booking.bookingEnd >= date
        )
      : [];

    setPendingBookings(pending);
  };

  const onStatusChange = (booking, status, bookings) => {
    updateBookingStatus(booking._id, status)
      .then(() => {
        updateBookingStateOnUpdate(
          setBookingData,
          bookings || bookingData,
          booking._id,
          status
        );

        sendMassage(
          `Zmiana statusu rezerwacji`,
          `Twoja prośba o '${booking.roomId.name}' została ${
            parseInt(status, 10) === 3 ? 'zaakceptowana' : 'odrzucona'
          }.`,
          booking.email
        );
      })
      .catch(error => console.error(error.message));
  };

  useEffect(() => {
    if (currentUser.role !== roles.admin) return;

    if (bookingData) {
      filterBookings(bookingData, currentDataDate);
    } else {
      listBookings()
        .then(response => {
          setBookingData(response.bookings);
          setCurrentDataDate(response.currentDate);

          filterBookings(response.bookings, response.currentDate);

          if (bookingId)
            getBooking(bookingId).then(booking => {
              if (!booking._id) return;

              if (booking.status === 1)
                onStatusChange(booking, bookingStatus, response.bookings);
            });
        })
        .catch(error => console.error('Error loading booking data', error));
    }
  }, [currentUser, bookingData, currentDataDate]);

  if (currentUser.role !== roles.admin) return 'Brak dostępu.';

  return (
    <Layout
      currentUser={currentUser}
      navToggler={navToggler}
      title="Rezerwacje oczekujące na potwierdzenie"
      contentClass="my-bookings pending-bookings"
    >
      <Styled.PendingBookings>
        {pendingBookings.length !== 0 && (
          <div className="table-wrapper">
            <Table>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Data</th>
                  <th>Pomieszczenie</th>
                  <th>Pracownik</th>
                </tr>
              </thead>
              <tbody>
                {pendingBookings.map(booking => (
                  <PendingBookingRow
                    booking={booking}
                    onStatusChange={onStatusChange}
                    key={booking._id}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {pendingBookings.length === 0 && <p>Brak</p>}
      </Styled.PendingBookings>
    </Layout>
  );
};

export default PendingBookings;
