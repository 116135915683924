import React, { useState } from 'react';
import { TopbarFilters as StyledTopbarFilters } from './TopbarFilters.styles';
import { floorParams } from '../../helpers/filters';
import Dropdown from '../Dropdown';
import Switcher from '../Switcher';
import { InputRadio } from '../Filters/Filters.styles';
import Icon from '../Icon/Icon';

const TopbarFilters = props => {
  const { toggledView, setToggledView, filters, onSetFloor } = props;
  const [checkedRadioName, setCheckedRadioName] = useState('Wszystkie');

  const handleChange = (event, item) => {
    onSetFloor(event.target.value);
    setCheckedRadioName(item.name);
  };

  return (
    <StyledTopbarFilters>
      <Switcher setToggledView={setToggledView} toggledView={toggledView} />

      <Dropdown
        labelIcon={<Icon icon="floor-plan" size={36} className="label-icon" />}
        header={<span className="trigger-text">{checkedRadioName}</span>}
      >
        {floorParams.map((item, index) => (
          <InputRadio className="input-radio-box" key={item.value}>
            <input
              type="radio"
              id={`floor${index}`}
              value={item.value}
              onChange={event => handleChange(event, item)}
              name="floor"
              defaultChecked={filters.floor === item.value}
              className="input-radio"
            />
            <label htmlFor={`floor${index}`} className="input-radio-label">
              {item.name}
            </label>
          </InputRadio>
        ))}
      </Dropdown>
    </StyledTopbarFilters>
  );
};

export default TopbarFilters;
