import api from './init';

export function getAccessToken(data) {
  const params = data;
  params.date = new Date();

  return api
    .get('/token', {
      params
    })
    .then(res => res.data)
    .catch(res => {
      throw res.response.data;
    });
}
