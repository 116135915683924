import React from 'react';
import * as Styled from './Switcher.styles';
import themes from '../../config/theme';
import { setUserSetting } from '../../helpers/users';

const Switcher = ({ toggledView, setToggledView }) => {
  const switcherIconSize = 22;

  const handleOnClick = () => {
    setUserSetting('toggledView', !toggledView);
    setToggledView(!toggledView);
  };

  return (
    <Styled.Switcher
      onClick={handleOnClick}
      aria-label="Przełącznik lista pomieszczeń/rzuty"
    >
      <Styled.SwitcherIcon
        className="frequency"
        icon="frequency"
        size={switcherIconSize}
        color={themes.base.colors.primary}
      />
      <Styled.SwitcherIcon
        className="view"
        icon="view"
        size={switcherIconSize}
        color={themes.base.colors.primary}
      />
      <Styled.SwitcherThumb toggledView={toggledView}>
        {toggledView ? (
          <Styled.SwitcherIcon
            icon="view"
            size={switcherIconSize}
            color="#fff"
          />
        ) : (
          <Styled.SwitcherIcon
            icon="frequency"
            size={switcherIconSize}
            color="#fff"
          />
        )}
      </Styled.SwitcherThumb>
    </Styled.Switcher>
  );
};

export default Switcher;
