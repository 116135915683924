import styled from 'styled-components';

const Footer = styled.footer`
  width: 100%;
  padding: 20px 0;
  text-align: center;
  background-color: ${props => props.theme.colors.white};
`;

export { Footer };
