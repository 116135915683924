import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Layout from '../../layouts/Layout/Layout';
import Content from '../../layouts/Content';
import Sidebar from '../../layouts/Sidebar';
import * as Styled from './AttendanceList.styles';
import 'moment/locale/pl';
import Calendar from '../../components/Calendar';
import AlertBlock from '../../components/AlertBlock';
import { H3 } from '../../components/Typography/Typography.styles';
import TableContentLoader from '../../components/ContentLoader/TableContentLoader';
import AttendanceForm from './AttendanceForm';
import AttendanceTable from './AttendanceTable';
import Box from '../../layouts/Box';
import { attendanceList } from '../../api/presence';
import { filterAttendanceByDate } from '../../helpers/presence';

const AttendanceList = props => {
  const {
    calendarDate,
    setCalendarDate,
    currentUser,
    navToggler,
    currentAttendanceDate,
    setCurrentAttendanceDate,
    attendanceData,
    setAttendanceData,
    bookingData,
    setBookingData,
    userBookings,
    setUserBookings
  } = props;
  const [filteredData, setFilteredData] = useState([]);

  const loadMissingData = () => {
    attendanceList({
      fromDate: currentAttendanceDate
    })
      .then(response => {
        setCurrentAttendanceDate(response.currentDate);

        if (response.data)
          setAttendanceData([...attendanceData].concat(response.data));
      })
      .catch(error => console.error('Error loading booking data', error));
  };

  // load all necessary data
  useEffect(() => {
    if (attendanceData.length === 0) {
      attendanceList()
        .then(response => {
          setAttendanceData(response.data);
          setCurrentAttendanceDate(response.currentDate);
        })
        .catch(error => console.error('Error loading booking data', error));
    } else {
      // load missing data
      loadMissingData();
    }
  }, []);

  useEffect(() => {
    const filteredByDate = filterAttendanceByDate(attendanceData, calendarDate);

    setFilteredData(filteredByDate);
    // setFilteredData(filteredByDate => filteredByDate);
  }, [calendarDate, attendanceData]);

  return (
    <Layout
      currentUser={currentUser}
      navToggler={navToggler}
      title={`Zgłaszanie obecności (${moment(calendarDate).format(
        'D MMMM YYYY'
      )})`}
    >
      <Sidebar className="sidebar-sticky">
        <Calendar
          calendarDate={calendarDate}
          setCalendarDate={setCalendarDate}
          dateFormat="YYYY-MM-DD"
          timeFormat={false}
          input
          value={moment(calendarDate)}
          renderInput={() => (
            <div className="calendar-aside">
              {moment(calendarDate).format('dddd, [\n] LL')}
            </div>
          )}
          utc={false}
          locale="pl"
          onChange={event => setCalendarDate(event._d)}
        />
      </Sidebar>
      <Content>
        <Styled.AttendanceList>
          <AlertBlock info large icon="info">
            <H3>Kiedy Twoja obecność jest rejestrowana automatycznie?</H3>
            <p>
              Podczas rezerwacji hotdesków lub miejsc garażowych dla
              pracowników.
            </p>
            <p>
              Podczas rezerwacji salek z zaznaczoną opcją &quot;Zgłoś obecność
              do rezerwacji&quot;.
            </p>
            <H3>Kiedy Twoja wizyta wymaga zgłoszenia obecności?</H3>
            <p>
              Podczas, gdy nie rezerwujesz żadnego zasobu, a chcesz pojawić się
              w biurze
            </p>
          </AlertBlock>

          <Box header="Zgłoś obecność">
            <AttendanceForm
              currentUser={currentUser}
              calendarDate={moment(calendarDate)}
              loadMissingData={loadMissingData}
            />
          </Box>

          <Box header="Lista obecności">
            {attendanceData.length === 0 && <TableContentLoader />}
            {filteredData.length !== 0 ? (
              <AttendanceTable
                currentUser={currentUser}
                currentAttendanceDate={currentAttendanceDate}
                attendanceData={attendanceData}
                filteredData={filteredData}
                setAttendanceData={setAttendanceData}
                bookingData={bookingData}
                setBookingData={setBookingData}
                userBookings={userBookings}
                setUserBookings={setUserBookings}
              />
            ) : (
              attendanceData.length !== 0 && (
                <p>Brak zgłoszonych obecności na ten dzień.</p>
              )
            )}
          </Box>
        </Styled.AttendanceList>
      </Content>
    </Layout>
  );
};

export default AttendanceList;
