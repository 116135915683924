import React from 'react';
import 'moment/locale/pl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import Button from '../../../components/Button';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { messages } from '../../../helpers/forms';
import { reportAttendance } from '../../../api/presence';

const AttendanceForm = ({ currentUser, calendarDate, loadMissingData }) => {
  const alert = useAlert();

  const handleSubmit = (values, setSubmitting, resetForm) => {
    try {
      reportAttendance(currentUser, values.startPresence, values.endPresence)
        .then(() => {
          loadMissingData();

          setSubmitting(false);
          resetForm({});
          alert.success('Obecność zgłoszona.');
        })
        .catch(err => {
          loadMissingData();
          alert.info(err.response.data.error.message.match(/error:.+/i)[0]);
        });
    } catch (e) {
      alert.error(e.message);
    }
  };

  return (
    <Formik
      initialValues={{
        startPresence: calendarDate,
        endPresence: ''
      }}
      enableReinitialize
      validationSchema={Yup.object({
        startPresence: Yup.string().required(messages.required)
      })}
      onSubmit={(values, { setSubmitting, resetForm }) =>
        handleSubmit(values, setSubmitting, resetForm)
      }
    >
      {({ values, errors, touched }) => (
        <Form noValidate>
          <CustomDatePicker
            name="startPresence"
            type="text"
            label="Obecność dnia"
            error={errors.startPresence}
            touched={touched.startPresence}
            inputValue={values.startPresence}
            dateFormat="yyyy-MM-dd"
            popperPlacement="top-start"
            disabled
          />
          <CustomDatePicker
            name="endPresence"
            type="text"
            label="Powtórz do"
            error={errors.endPresence}
            touched={touched.endPresence}
            inputValue={values.endPresence}
            dateFormat="yyyy-MM-dd"
            popperPlacement="top-start"
          />
          <div className="text-right">
            <Button primary large text="Zgłoś obecność" type="submit" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AttendanceForm;
