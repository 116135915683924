import React, { useState } from 'react';
import * as Styled from './Filters.styles';
import { capacityParams, featureParams } from '../../helpers/filters';
import Dropdown from '../Dropdown';

const Filters = ({ onSetCapacity, onSetFeature }) => {
  const [checkedCapacity, setCheckedCapacity] = useState([]);
  const [checkedFeature, setCheckedFeature] = useState([]);

  const handleChangeCapacity = (event, item) => {
    onSetCapacity(event.target.value);

    if (event.target.checked) {
      setCheckedCapacity([...checkedCapacity, item]);
    } else {
      setCheckedCapacity(prev =>
        prev.filter(currItem => currItem.value !== item.value)
      );
    }
  };

  const handleChangeFeature = (event, item) => {
    onSetFeature(event.target.value);

    if (event.target.checked) {
      setCheckedFeature([...checkedFeature, item]);
    } else {
      setCheckedFeature(prev =>
        prev.filter(currItem => currItem.value !== item.value)
      );
    }
  };

  return (
    <Styled.Filters>
      <form className="filters-form">
        <Dropdown
          header={
            <>
              <span className="trigger-text">Pojemność</span>
              {checkedCapacity.length !== 0 && (
                <span className="checked-count">{checkedCapacity.length}</span>
              )}
            </>
          }
        >
          {capacityParams.map((item, index) => (
            <Styled.InputCheckbox
              className="input-checkbox-box"
              key={item.value}
              onChange={event => handleChangeCapacity(event, item)}
            >
              <input
                type="checkbox"
                id={`capacity${index}`}
                value={item.value}
                name={`capacity${index}`}
                className="input-checkbox"
              />
              <label
                htmlFor={`capacity${index}`}
                className="input-checkbox-label"
              >
                {item.name}
              </label>
            </Styled.InputCheckbox>
          ))}
        </Dropdown>

        <Dropdown
          header={
            <>
              <span className="trigger-text">Urządzenia</span>
              {checkedFeature.length !== 0 && (
                <span className="checked-count">{checkedFeature.length}</span>
              )}
            </>
          }
        >
          {featureParams.map((item, index) => (
            <Styled.InputCheckbox
              className="input-checkbox-box"
              key={item.value}
              onChange={event => handleChangeFeature(event, item)}
            >
              <input
                type="checkbox"
                id={`feature${index}`}
                value={item.value}
                name={`feature${index}`}
                className="input-checkbox"
              />
              <label
                htmlFor={`feature${index}`}
                className="input-checkbox-label"
              >
                {item.name}
              </label>
            </Styled.InputCheckbox>
          ))}
        </Dropdown>
      </form>
    </Styled.Filters>
  );
};

export default Filters;
