import styled from 'styled-components';

const PageWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #333;
`;

export { PageWrapper };
