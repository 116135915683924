import styled from 'styled-components';

const RoomsList = styled.div`
  td,
  th {
    padding: 10px 15px;
  }

  .table__cell--header {
    white-space: nowrap;
  }

  .table-wrapper {
    overflow-x: auto;
  }

  .table__cell,
  .table__cell--available {
    position: relative;
  }

  .table__link--available,
  .table__cell--booked {
    position: absolute;
    top: 0;
    left: -1px;
    right: -1px;
    bottom: 0;
    display: inline-block;
    width: calc(100% + 2px);
    padding: 10px;
    cursor: pointer;
    z-index: 1;
  }

  .table__link--available {
    &:hover,
    &:focus {
      background: ${props => props.theme.colors.primary};
    }
  }

  .table__cell--booked {
    top: calc(50% - 12px);
    height: 24px;
    background: ${props => props.theme.department.other};

    &.status--pending {
      opacity: 0.5;
    }

    &.table__cell {
      &--finance {
        background: ${props => props.theme.department.finanse};
      }
      &--hr {
        background: ${props => props.theme.department.hr};
      }
      &--legaloperations {
        background: ${props => props.theme.department.legaloperations};
      }
      &--kreacjaeventowa {
        background: ${props => props.theme.department.kreacjaeventowa};
      }
      &--events {
        background: ${props => props.theme.department.eventy};
      }
      &--clientservice {
        background: ${props => props.theme.department.clientservice};
      }
      &--strategy {
        background: ${props => props.theme.department.strategia};
      }
      &--kreacja,
      &--creation,
      &--creationzdalnie {
        background: ${props => props.theme.department.kreacja};
      }
      &--socialmedia {
        background: ${props => props.theme.department.socialmedia};
      }
      &--development {
        background: ${props => props.theme.department.development};
      }
      &--pmo {
        background: ${props => props.theme.department.pmo};
      }
      &--uxui,
      &--ux,
      &--ui {
        background: ${props => props.theme.department.uxui};
      }
      &--publicrelations {
        background: ${props => props.theme.department.publicrelations};
      }
      &--businessdevelopment {
        background: ${props => props.theme.department.businessdevelopment};
      }
      &--zarzad,
      &--boa {
        background: ${props => props.theme.department.zarzad};
      }
    }

    &.my__booking {
      background-image: repeating-linear-gradient(
        45deg,
        ${props => props.theme.colors.transparent},
        ${props => props.theme.colors.transparent} 33.3333%,
        ${props => props.theme.colors.translucent} 33.3333%,
        ${props => props.theme.colors.translucent} 66.6667%,
        ${props => props.theme.colors.transparent} 66.6667%
      );
    }

    &.table__cell--second-half-hour {
      border-top-left-radius: ${props => props.theme.borderRadius};
      border-bottom-left-radius: ${props => props.theme.borderRadius};

      &.my__booking {
        background-image: repeating-linear-gradient(
          45deg,
          ${props => props.theme.colors.translucent},
          ${props => props.theme.colors.translucent} 45%,
          ${props => props.theme.colors.transparent} 45%,
          ${props => props.theme.colors.transparent} 100%
        );
      }
    }

    &.table__cell--first-half-hour {
      border-top-right-radius: ${props => props.theme.borderRadius};
      border-bottom-right-radius: ${props => props.theme.borderRadius};

      &.my__booking {
        background-image: repeating-linear-gradient(
          45deg,
          ${props => props.theme.colors.transparent},
          ${props => props.theme.colors.transparent} 55%,
          ${props => props.theme.colors.translucent} 55%,
          ${props => props.theme.colors.translucent} 100%
        );
      }
    }
  }

  .table__cell--start {
    left: 2px;
    right: -1px;
    border-top-left-radius: ${props => props.theme.borderRadius};
    border-bottom-left-radius: ${props => props.theme.borderRadius};
    width: calc(100% - 1px);

    &.table__cell--end {
      width: calc(100% - 4px);
    }
  }

  .table__cell--end {
    right: 2px;
    border-top-right-radius: ${props => props.theme.borderRadius};
    border-bottom-right-radius: ${props => props.theme.borderRadius};
    width: calc(100% - 2px);
  }

  .table__cell--half {
    width: calc(50% - 3px);

    &.table__cell--start.table__cell--end {
      width: calc(50% - 4px);
    }
  }

  .table__cell--second-half-hour {
    left: auto;
    right: -1px;

    &.table__cell--end {
      right: 2px;
    }
  }

  .table__cell--first-half-hour {
    left: -1px;
    right: auto;

    &.table__cell--start {
      left: 2px;
    }
  }

  .table__row--disabled {
    &:hover {
      background-color: transparent !important;
    }

    .table__cell--header,
    .table__cell--disabled {
      cursor: not-allowed;
    }

    .table__cell--header {
      color: ${props => props.theme.colors.darkenGray};
    }
  }

  th .table__cell--header-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .table__data--asset-list {
    display: flex;
    flex-flow: row nowrap;
    vertical-align: middle;
    margin-left: auto;
  }

  .table__room-capacity {
    margin-left: 4px;
    padding-right: 16px;
    font-size: 12px;

    svg {
      max-height: 18px;
      max-width: 20px;
      margin-top: -2px;
      vertical-align: middle;
    }
  }

  @keyframes slideAnimation {
    15% {
      opacity: 1;
    }
    35% {
      transform: translateX(0);
    }
    45% {
      transform: translateX(10px);
    }
    55% {
      transform: translateX(-10px);
    }
    65% {
      transform: translateX(0);
    }
    85% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .slide-info {
    display: none;
  }

  .scrolled {
    .slide-info {
      position: absolute;
      top: 150px;
      left: calc(50% - 30px);
      display: block;
      width: 60px;
      height: 60px;
      padding-top: 14px;
      border-radius: 50%;
      pointer-events: none;
      opacity: 0;
      z-index: 5;

      &.active {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        background-color: #fff;
        text-align: center;
        animation: slideAnimation 2s linear 1;
      }
    }
  }
`;

export { RoomsList };
