import React from 'react';
import Datetime from 'react-datetime';
import * as Styled from './Calendar.styles';
// import ColorOfTheWeek from '../ColorOfTheWeek';

const Calendar = props => {
  // const { calendarDate } = props;

  return (
    <>
      <Styled.Calendar className="calendar-box">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Datetime {...props} />
      </Styled.Calendar>
      {/* <ColorOfTheWeek calendarDate={calendarDate} /> */}
    </>
  );
};
export default Calendar;
