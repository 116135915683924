import React from 'react';
import * as Styled from './MainWrapper.styles';

const MainWrapper = ({ children, navToggler, closeClick, mainWrapperRef }) => (
  <Styled.MainWrapper
    ref={mainWrapperRef}
    id="main-wrapper"
    className={navToggler.navOpened ? 'nav-expanded' : ''}
  >
    {navToggler.navOpened && <Styled.MainWrapperOverlay onClick={closeClick} />}
    {children}
  </Styled.MainWrapper>
);

export default MainWrapper;
