const base = {
  backgroundColor: '#EBF1F5',
  fontColor: '#212529',
  fontSize: '16px',
  fontSizeSmall: '12px',
  maxPageWidth: '1920px',
  linkColor: 'blue',
  borderRadius: '10px',
  borderRadiusInner: '4px',
  boxShadow: '0 0 40px 0 rgba(0,0,0,.2);',

  transitons: {
    bezier: 'cubic-bezier(0.50, 0.50, 0.05, 0.95)',
    bezierSecondary: 'cubic-bezier(0.07, 0.95, 0, 1)'
  },

  colors: {
    primary: '#2876BC',
    primaryDarken: '#283991',
    primaryLight: '#cbe6ff',
    orange: '#F1582A',
    secondary: '#ED1C25',
    secondaryDark: '#bd0f18',
    lightGray: '#F9F9F9',
    darkenGray: '#d9d9d9',
    semiGray: '#666666',
    defaultGray: '#dadce0',
    initialGray: '#5f6368',
    alert: '#DC3546',
    alertFocus: '#e87b86',
    success: '#28a745',
    successFocus: '#3c763d',
    garageLine: '#fce025',
    garageFloor: '#999',
    semiBlue: '#71C9F8',
    lightBlue: '#D8EFFE',
    darkenBlue: '#6EB8E4',
    semiGreen: '#43D475',
    lightGreen: '#C3F3D8',
    darkenGreen: '#4DA677',
    semiRed: '#F54659',
    lightRed: '#FDE1E3',
    darkenRed: '#EE8195',
    semiYellow: '#F9A407',
    lightYellow: '#FBDB9C',
    darkenYellow: '#CF9F44',
    white: '#fff',
    black: '#000',
    navLink: '#9b9b9b',
    transparent: 'rgba(0, 0, 0, 0)',
    translucent: 'rgba(255, 255, 255, 0.3)',

    alerts: {
      danger: '#EC5865',
      info: '#0587BB',
      warning: '#F2B747',
      success: '#4AB867'
    }
  },

  colorShadows: {
    primaryShadow: '0 0 0 3px rgb(13 110 253 / 25%)',
    errorShadow: '0 0 0 3px rgb(253 13 13 / 25%)'
  },

  department: {
    finanse: '#897C87',
    hr: '#F5BFD2',
    legaloperations: '#E5DB9C',
    kreacjaeventowa: '#D0BCAC',
    eventy: '#d4b4ec',
    clientservice: '#218B82',
    strategia: '#9AD9DB',
    kreacja: '#98D4BB',
    socialmedia: '#C54B6C',
    development: '#26474E',
    pmo: '#7B92AA',
    uxui: '#F7CE76',
    publicrelations: '#AF8C72',
    businessdevelopment: '#938F43',
    zarzad: '#40393E',
    other: '#E6A57E'
  },

  sizes: {
    baseMargin: '20px',
    basePadding: '20px'
  },

  breakpoints: {
    xs: '0',
    sm: '576px',
    md: '768px',
    mdUp: '769px',
    lg: '992px',
    lgUp: '993px',
    xl: '1200px',
    xlUp: '1201px',
    xxl: '1440px',
    xxlUp: '1441px'
  }
};

export default {
  base
};
