import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import * as Styled from './Authentication.styles';
import themes from '../../config/theme';
import GlobalStyle from '../../GlobalStyle';
import PageWrapper from '../../layouts/PageWrapper';
import Logo from '../../layouts/Logo';
import { HtmlHead } from '../../layouts/HtmlHead/HtmlHead';

const Authentication = ({ children }) => {
  const [mouseXpercentage, setMouseXpercentage] = useState(0);
  const [mouseYpercentage, setMouseYpercentage] = useState(0);

  const handleMouseMove = event => {
    setMouseXpercentage(Math.round((event.pageX / window.innerWidth) * 100));
    setMouseYpercentage(Math.round((event.pageY / window.innerHeight) * 100));
  };

  return (
    <ThemeProvider theme={themes.base}>
      <HtmlHead />
      <GlobalStyle />
      <PageWrapper>
        <Styled.Authentication
          onMouseMove={event => handleMouseMove(event)}
          style={{
            background: `radial-gradient(at ${mouseXpercentage}% ${mouseYpercentage}%, #3498db, #9b59b6 `
          }}
        >
          <Styled.AuthenticationWrapper>
            <div className="auth-aside">
              <Logo />
              Witaj w systemie rezerwacji hotdesków, sal i miejsc garażowych
            </div>
            <div className="auth-main">{children}</div>
          </Styled.AuthenticationWrapper>
        </Styled.Authentication>
      </PageWrapper>
    </ThemeProvider>
  );
};

export default Authentication;
