import styled from 'styled-components';

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding-bottom: 15px;

  input,
  select,
  textarea {
    border: 2px solid ${props => props.theme.colors.defaultGray};

    &:focus,
    &:hover {
      border-color: ${props => props.theme.colors.primary};
      outline: 0;

      & + label {
        color: ${props => props.theme.colors.primary};

        > span {
          color: currentColor;
        }
      }
    }

    &:disabled {
      cursor: not-allowed;
      background-color: ${props => props.theme.colors.lightGray};
      border: 2px solid ${props => props.theme.colors.defaultGray};
      color: ${props => props.theme.colors.defaultGray};

      & + label {
        cursor: not-allowed;
        color: ${props => props.theme.colors.defaultGray};

        > span {
          background-color: ${props => props.theme.colors.lightGray};
        }
      }
    }
  }

  input:not([type='checkbox']):not([type='radio']):not([type='button']):not([type='submit']):not([type='range']),
  select,
  textarea {
    width: 100%;
    display: block;
    padding: 20px 16px;
    appearance: none;
    font-size: ${props => props.theme.fontSize};
    border-radius: ${props => props.theme.borderRadiusInner};

    &:focus,
    &:not(:placeholder-shown) {
      & ~ label {
        transform: scale(0.85) translateY(-29px);
      }
    }

    & + label {
      position: absolute;
      top: 0;
      left: 0;
      padding: 20px 16px;
      pointer-events: none;
      transform-origin: 0 0;
      transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;

      > span {
        color: currentColor;
      }
    }

    &::placeholder {
      color: transparent;
    }
  }

  select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23${props =>
      props.theme.colors.initialGray
        .split('#')
        .join(
          ''
        )}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    color: ${props => props.theme.colors.initialGray};

    &:disabled {
      background: ${props => props.theme.colors.lightGray}url
        (
          "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23${props =>
            props.theme.colors.defaultGray
              .split('#')
              .join(
                ''
              )}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"
        )
        right 0.75rem center/16px 12px no-repeat;
    }

    &:not(:disabled):focus,
    &:not(:disabled):hover {
      color: ${props => props.theme.fontColor};
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23${props =>
        props.theme.colors.primary
          .split('#')
          .join(
            ''
          )}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    }
  }

  &.checkbox-wrapper {
    display: flex;
    align-content: center;
    flex-direction: row;
    margin-top: 20px;

    label {
      margin: 0;
      order: unset;

      > span {
        padding: 0;
        border-radius: 0;
      }
    }
  }

  [type='checkbox'] {
    outline: none;
    width: 18px;
    height: 18px;
    margin-right: 6px;
    background-color: #fff;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 2px solid ${props => props.theme.colors.defaultGray};
    appearance: none;
    color-adjust: exact;

    &:checked {
      background-color: ${props => props.theme.colors.primary};
      border-color: ${props => props.theme.colors.primary};
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }

    &:disabled {
      opacity: 0.5;

      & + label {
        > span {
          background-color: transparent;
        }
      }
    }
  }

  textarea {
    resize: none;
  }

  &.filed-error {
    input,
    select,
    textarea {
      border-color: ${props => props.theme.colors.alert};
      color: ${props => props.theme.colors.alert};

      & + label {
        color: ${props => props.theme.colors.alert};
      }

      &[type='checkbox'] {
        &:checked {
          background-color: ${props => props.theme.colors.alert};
          border-color: ${props => props.theme.colors.alert};
        }
      }

      &:not(:disabled):focus,
      &:not(:disabled):hover {
        border-color: ${props => props.theme.colors.alertFocus};
        color: ${props => props.theme.colors.alertFocus};

        & + label {
          color: ${props => props.theme.colors.alertFocus};
        }

        &[type='checkbox'] {
          &:checked {
            background-color: ${props => props.theme.colors.alertFocus};
            border-color: ${props => props.theme.colors.alertFocus};
          }
        }

        & ~ [role='alert'] {
          color: ${props => props.theme.colors.alertFocus};
        }
      }
    }

    select {
      color: ${props => props.theme.fontColor};
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23${props =>
        props.theme.colors.alert
          .split('#')
          .join(
            ''
          )}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");

      &:not(:disabled):focus,
      &:not(:disabled):hover {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23${props =>
          props.theme.colors.alertFocus
            .split('#')
            .join(
              ''
            )}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      }
    }
  }

  &.filed-success {
    input,
    select,
    textarea {
      border-color: ${props => props.theme.colors.success};
      color: ${props => props.theme.fontColor};

      & + label {
        color: ${props => props.theme.colors.success};
      }

      &[type='checkbox'] {
        &:checked {
          background-color: ${props => props.theme.colors.success};
          border-color: ${props => props.theme.colors.success};
        }
      }

      &:not(:disabled):focus,
      &:not(:disabled):hover {
        border-color: ${props => props.theme.colors.successFocus};

        & + label {
          color: ${props => props.theme.colors.successFocus};
        }

        &[type='checkbox'] {
          &:checked {
            border-color: ${props => props.theme.colors.successFocus};
            background-color: ${props => props.theme.colors.successFocus};
          }
        }
      }
    }

    select {
      color: ${props => props.theme.fontColor};
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23${props =>
        props.theme.colors.success
          .split('#')
          .join(
            ''
          )}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");

      &:not(:disabled):focus,
      &:not(:disabled):hover {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23${props =>
          props.theme.colors.successFocus
            .split('#')
            .join(
              ''
            )}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      }
    }
  }
`;

const FieldLabel = styled.label`
  position: relative;
  order: -1;
  display: block;
  margin-bottom: 4px;
  font-size: ${props => props.theme.fontSize};
  color: ${props => props.theme.colors.initialGray};

  > span {
    position: relative;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: ${props => props.theme.colors.white};
  }
`;

const FieldMsg = styled.small`
  position: absolute;
  left: 16px;
  bottom: -5px;
  font-size: ${props => props.theme.fontSizeSmall};
  color: ${props => props.theme.colors.alert};
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 17px;
  right: 20px;
  width: 26px;
  height: 26px;
  line-height: 26px;
  font-size: 20px;
  border-radius: 50%;
  color: #fff;
  background-color: ${props => props.theme.colors.primary};

  &:hover {
    background-color: ${props => props.theme.colors.primaryDarken};
  }
`;

export { InputWrapper, FieldLabel, FieldMsg, RemoveButton };
