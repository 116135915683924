import styled from 'styled-components';

const Calendar = styled.div`
  overflow: hidden;
  margin-bottom: 20px;
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius};

  .rdt {
    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      display: flex;
    }

    table {
      width: 100%;
    }

    // css for .calendar-aside wrapper
    > div {
      &:not(.rdtPicker):first-child {
        display: flex;
        width: 100%;
        padding: 10px;
        background-color: ${props => props.theme.colors.primary};

        @media (max-width: ${props => props.theme.breakpoints.xxl}) {
          border-top-left-radius: ${props => props.theme.borderRadiusInner};
          border-top-right-radius: ${props => props.theme.borderRadiusInner};
        }

        @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
          //padding: 10px;
          min-width: 120px;
          width: 30%;
          flex-shrink: 0;
          border-top-left-radius: ${props => props.theme.borderRadiusInner};
          border-bottom-left-radius: ${props => props.theme.borderRadiusInner};
        }
      }
    }
  }

  .calendar-aside {
    display: flex;
    width: 100%;
    color: ${props => props.theme.colors.white};
    //white-space: pre-line;

    @media (max-width: ${props => props.theme.breakpoints.xxl}) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      flex-direction: column;
    }

    .aside-left {
      @media (max-width: ${props => props.theme.breakpoints.lg}) {
        margin-right: 5px;
      }

      @media (min-width: ${props => props.theme.breakpoints.xlUp}) {
        flex-grow: 1;
      }
    }

    .aside-right {
      //flex-shrink: 0;
      //text-align: right;
    }

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .rdt thead th {
    padding: 10px;
  }

  .rdtPicker {
    padding: 5px;
    border-radius: ${props => props.theme.borderRadiusInner};

    @media (min-width: ${props => props.theme.breakpoints.xlUp}) {
      flex-grow: 1;
    }
  }

  .rdtPicker table {
    @media (max-width: ${props => props.theme.breakpoints.xl}) {
      width: 100%;
    }
  }

  .rdtPicker th,
  .rdtPicker td {
    padding: 6px;
    text-align: center;

    @media (min-width: ${props => props.theme.breakpoints.xlUp}) {
      padding: 4px;
    }

    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      padding: 8px;
    }
  }

  .rdtPicker td {
    cursor: pointer;
  }

  .rdtSwitch {
    text-transform: capitalize;
    font-weight: 700;

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      font-size: 18px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      font-size: 20px;
    }
  }

  .rdtTimeToggle,
  .rdtDay,
  .rdtHour,
  .rdtMinute,
  .rdtSecond {
    font-weight: 400;

    &:hover {
      cursor: pointer;
      font-weight: 500;
      border-radius: ${props => props.theme.borderRadiusInner};
    }
  }

  .rdtOld {
    transform: scale(0.9);
    color: ${props => props.theme.colors.darkenGray};
  }

  .rdtDay {
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      height: 32px;
      width: 32px;
      border: 1px solid transparent;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.5s;
    }

    &:hover {
      color: ${props => props.theme.fontColor};

      &:after {
        opacity: 1;
        border-color: ${props => props.theme.fontColor};
      }
    }
  }

  .rdtToday {
    color: ${props => props.theme.colors.semiGray};

    &:after {
      opacity: 1;
      border-color: ${props => props.theme.colors.semiGray};
    }

    &:hover {
      color: ${props => props.theme.fontColor};

      &:after {
        border-color: ${props => props.theme.fontColor};
      }
    }
  }

  .rdtActive {
    color: ${props => props.theme.colors.white};

    &:after {
      background-color: ${props => props.theme.colors.secondary};
      border-color: ${props => props.theme.colors.secondary};
      opacity: 1;
    }

    &:hover {
      color: ${props => props.theme.colors.white};

      &:after {
        background-color: ${props => props.theme.colors.alertFocus};
        border-color: ${props => props.theme.colors.alertFocus};
      }
    }
  }

  th.rdtDisabled,
  td.rdtDisabled,
  span.rdtDisabled {
    background: none;
    color: ${props => props.theme.colors.darkenGray};
    cursor: not-allowed;

    &:hover {
      background: none;
      color: ${props => props.theme.colors.darkenGray};
      cursor: not-allowed;
    }
  }

  span.rdtOld {
    color: ${props => props.theme.colors.darkenGray};
  }

  .dow {
    font-weight: 500;
  }

  th.rdtNext,
  th.rdtPrev {
    font-size: 20px;
    vertical-align: baseline;
  }

  .rdtPrev span,
  .rdtNext span {
    user-select: none;
  }

  .rdtPicker thead {
    tr:first-child th {
      cursor: pointer;
    }
  }

  td.rdtMonth,
  td.rdtYear,
  th.rdtPrev,
  th.rdtNext,
  th.rdtSwitch {
    cursor: pointer;
    border-radius: ${props => props.theme.borderRadiusInner};

    &:hover {
      background-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white};
      font-weight: 500;
    }
  }

  .rdtMonth,
  .rdtYear {
    &.rdtActive {
      background-color: ${props => props.theme.colors.secondary};

      &:hover {
        background-color: ${props => props.theme.colors.alertFocus};
      }
    }
  }
`;

export { Calendar };
