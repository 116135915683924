import React, { useEffect } from 'react';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import * as Styled from './ReactAlert.styles';
import Button from '../Button';
import Icon from '../Icon/Icon';

const FocusTrap = require('focus-trap-react');

const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 10000,
  transition: transitions.FADE,
  offset: '10px',
  containerStyle: {
    height: '100%',
    zIndex: 102,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    pointerEvents: 'all'
  }
};

const AlertTemplate = ({ style, options, message, close }) => {
  const HandleKey = e => {
    if (e.key === 'Escape') {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', HandleKey);
    return () => document.removeEventListener('keydown', HandleKey);
  });

  return (
    <FocusTrap>
      <Styled.ReactAlert style={style}>
        <div className={`alert-icon-box alert-icon-box-${options.type}`}>
          <Icon
            icon={`alert-${options.type}`}
            removeInlineStyle
            className="alert-icon"
          />
        </div>
        <p className="alert-msg-box">{message}</p>
        <Styled.AlertBtnBox>
          {options.customElements ? options.customElements() : null}
          <Button
            onClick={close}
            success={options.type === 'success'}
            alert={options.type === 'error'}
            primary={options.type === 'info'}
          >
            Zamknij
          </Button>
        </Styled.AlertBtnBox>
      </Styled.ReactAlert>
    </FocusTrap>
  );
};

const ReactAlert = ({ children }) => (
  <>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      {children}
    </AlertProvider>
  </>
);

export default ReactAlert;
