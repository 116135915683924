import React from 'react';
import moment from 'moment';
import Button from '../../../components/Button';

const PendingBookingRow = ({ booking, onStatusChange }) => {
  return (
    <tr>
      <td>
        <Button
          confirm
          onlyIcon
          text="Zaakceptuj rezerwację"
          onClick={() =>
            // eslint-disable-next-line no-alert
            window.confirm('Na pewno chcesz zaakceptować rezerwację?') &&
            onStatusChange(booking, 3)
          }
        />
        <Button
          remove
          onlyIcon
          text="Usuń rezerwację"
          onClick={() =>
            // eslint-disable-next-line no-alert
            window.confirm('Na pewno chcesz odrzucić rezerwację?') &&
            onStatusChange(booking, 2)
          }
        />
      </td>
      <td>
        <span>
          {moment(booking.bookingStart).format('dddd, D MMMM YYYY H:mm')} -{' '}
          {moment(booking.bookingEnd).format('H:mm')}
        </span>
      </td>
      <td>
        {booking.roomId.name}
        {booking.description && <p className="small">{booking.description}</p>}
      </td>
      <td>{booking.email}</td>
    </tr>
  );
};

export default PendingBookingRow;
