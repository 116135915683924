import styled from 'styled-components';
import { NavLink as NativeNavLink } from 'react-router-dom';

const Logo = styled.h1`
  padding: 0;
`;

const LogoLink = styled(NativeNavLink)`
  display: block;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 60px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    width: 90px;
  }

  img {
    display: block;
    width: 100%;
  }
`;

export { Logo, LogoLink };
