import styled from 'styled-components';

const Account = styled.article`
  width: 100%;

  > section {
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      padding: 10px;

      h2 {
        padding: 5px 5px 0;
      }
    }

    > table {
      @media (max-width: ${props => props.theme.breakpoints.lg}) {
        display: flex;

        thead,
        tbody {
          tr {
            display: flex;
            flex-direction: column;
          }
          td,
          th {
            border-right: none;
            display: flex;
            align-items: center;
            min-height: 80px;
            word-wrap: break-word;

            @media (max-width: ${props => props.theme.breakpoints.lg}) {
              padding: 20px 10px;
            }
          }
        }

        thead {
          width: max-content;
        }

        tbody {
          width: 100%;
        }
      }
	    
	    
	    .department-badge {
        display: inline-block;
        width: 16px;
        height: 16px;
		    margin-left: 5px;
		    border-radius: 50%;
        vertical-align: bottom;
        background-color: ${props => props.theme.department.other};
		
		    &.department {
		      &--finance {
		        background: ${props => props.theme.department.finanse};
		      }
		      &--hr {
		        background: ${props => props.theme.department.hr};
		      }
		      &--legaloperations {
		        background: ${props => props.theme.department.legaloperations};
		      }
		      &--kreacjaeventowa {
		        background: ${props => props.theme.department.kreacjaeventowa};
		      }
		      &--events {
		        background: ${props => props.theme.department.eventy};
		      }
		      &--clientservice {
		        background: ${props => props.theme.department.clientservice};
		      }
		      &--strategy {
		        background: ${props => props.theme.department.strategia};
		      }
          &--kreacja,
          &--creation,
          &--creationzdalnie {
		        background: ${props => props.theme.department.kreacja};
		      }
		      &--socialmedia {
		        background: ${props => props.theme.department.socialmedia};
		      }
		      &--development {
		        background: ${props => props.theme.department.development};
		      }
		      &--pmo {
		        background: ${props => props.theme.department.pmo};
		      }
          &--uxui,
          &--ux,
          &--ui {
		        background: ${props => props.theme.department.uxui};
		      }
		      &--publicrelations {
		        background: ${props => props.theme.department.publicrelations};
		      }
		      &--businessdevelopment {
		        background: ${props => props.theme.department.businessdevelopment};
		      }
          &--zarzad,
          &--boa {
		        background: ${props => props.theme.department.zarzad};
		      }
		    }
    }
  }
`;

export { Account };
