import React from 'react';
import * as Styled from './Button.styles';

const Button = props => {
  const { text, onlyIcon, children } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Styled.Button {...props}>
      {children || null}
      {onlyIcon ? <span className="sr-only">{text}</span> : text}
    </Styled.Button>
  );
};

export default Button;
