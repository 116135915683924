import React from 'react';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { useAlert } from 'react-alert';
import Button from '../../../../components/Button/Button';
import * as Styled from './MyBookingsRow.styles';

const MyBookingsRow = ({ bookingData, onDeleteBooking }) => {
  const alert = useAlert();

  const startTime = momentTimezone
    .tz(bookingData.bookingStart, 'Europe/Warsaw')
    .format('H:mm');
  const endTime = momentTimezone
    .tz(bookingData.bookingEnd, 'Europe/Warsaw')
    .format('H:mm');

  const setStatus = status => {
    switch (status) {
      case 1:
        return 'pending';
      case 2:
        return 'rejected';
      default:
        return '';
    }
  };

  return (
    <Styled.MyBookingsRow className={setStatus(bookingData.status)}>
      <th className="booking-row-header" scope="row">
        <span>{moment(bookingData.bookingStart).format('dddd')}</span>,{' '}
        <span>{moment(bookingData.bookingStart).format('D MMMM YYYY')}</span>{' '}
        <span>
          {startTime} - {endTime} ({bookingData.duration}h)
        </span>
      </th>
      <td>
        <p>{bookingData.roomId.name}</p>
        {bookingData.status !== 3 && (
          <p className="small">
            <strong>Status: </strong>
            {bookingData.status === 1
              ? 'Oczekująca na potwierdzenie'
              : 'Odrzucona'}
          </p>
        )}
      </td>
      {onDeleteBooking && (
        <td className="action">
          <Button
            remove
            onlyIcon
            onClick={() => {
              alert.info('Na pewno chcesz usunąć rezerwację?', {
                customElements: () => (
                  <Button
                    onClick={() => {
                      alert.removeAll();
                      onDeleteBooking(bookingData);
                    }}
                    alert
                  >
                    Usuń
                  </Button>
                )
              });
            }}
            text="Usuń rezerwację"
          />
        </td>
      )}
      <td>
        <p>{bookingData.purpose}</p>
        {bookingData.description && (
          <p className="small">{bookingData.description}</p>
        )}
      </td>
    </Styled.MyBookingsRow>
  );
};

export default MyBookingsRow;
