export const departmentName = [
  { name: 'Finance', value: 'finance' },
  { name: 'HR', value: 'hr' },
  { name: 'Legal & Operations', value: 'legalOperations' },
  { name: 'Kreacja eventowa', value: 'eventCreation' },
  { name: 'Events', value: 'events' },
  { name: 'Client service', value: 'clientService' },
  { name: 'Strategy', value: 'strategy' },
  { name: 'Kreacja', value: 'creative' },
  { name: 'Social media', value: 'socialMedia' },
  { name: 'Development', value: 'development' },
  { name: 'PMO', value: 'pmo' },
  { name: 'UX/UI', value: 'uxui' },
  { name: 'Public Relations', value: 'pr' },
  { name: 'Business development', value: 'businessDevelopment' },
  { name: 'Zarząd', value: 'companyManagement' },
  { name: 'Inne', value: 'other' }
];

export const userUnitKey = name => {
  return name
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ /g, '-')
    .replace(/[^\w\s]/gi, '')
    .toLowerCase();
};

export const setUserSetting = (name, value) => {
  let profile = JSON.parse(localStorage.getItem('profile'));

  if (!profile) profile = {};
  profile[name] = value;

  localStorage.setItem('profile', JSON.stringify(profile));
};

export const getCurrentToken = () => {
  const profile = JSON.parse(localStorage.getItem('profile'));

  return profile ? profile.token : null;
};
