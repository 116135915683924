import React from 'react';
import * as Styled from './Table.styles';

const Table = ({ children, className, id }) => (
  <Styled.Table className={className} id={id}>
    {children}
  </Styled.Table>
);

export default Table;
