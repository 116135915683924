import styled from 'styled-components';

const NavToggler = styled.button`
  //position: fixed;
  //top: 30px;
  //right: 20px;
  height: 20px;
  width: 20px;
  margin-left: 20px;
  display: grid;
  place-items: center;
  cursor: pointer;
  background: transparent;
  z-index: 101;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    top: 20px;
    right: 20px;
    height: 55px;
    width: 50px;
  }

  & > span {
    width: 20px;
    height: 2px;
    background: ${props => props.theme.colors.primary};
    display: block;
    transition: all 0.3s ease-in-out;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      height: 3px;
      width: 50px;
    }

    &:nth-child(1) {
      width: 15px;
      justify-self: end;

      @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
        width: 42px;
      }
    }

    &:nth-child(2) {
      width: 12px;
      justify-self: end;

      @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
        width: 35px;
      }
    }
  }

  &:hover > span {
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      &:nth-child(1) {
        width: 35px;
      }
      &:nth-child(2) {
        width: 40px;
      }
    }
  }

  &.active span {
    &:nth-child(1) {
      transform-origin: center center;
      transform: rotate(-48deg) translate(3px, 10px);
      width: 20px;

      @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
        transform: rotate(-45deg) translate(-12px, 12px);
        width: 55px;
      }
    }
    &:nth-child(2) {
      transform: translateX(10px);
      opacity: 0;
    }
    &:nth-child(3) {
      transform-origin: center center;
      transform: rotate(45deg) translate(0, -14px);
      width: 20px;

      @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
        transform: rotate(45deg) translate(-15px, -14px);
        width: 55px;
      }
    }
  }
`;

export { NavToggler };
