import React, { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Field, useField, useFormikContext } from 'formik';
import pl from 'date-fns/locale/pl';
import * as Styled from './CustomDatePicker.styles';
import * as StyledField from '../InputWrapper/InputWrapper.styles';

registerLocale('pl', pl);

const CustomDatePicker = ({
  name,
  type,
  label,
  error,
  touched,
  inputValue,
  children,
  dateFormat,
  popperPlacement,
  disabled
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);
  // eslint-disable-next-line no-unused-vars
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <StyledField.InputWrapper
      className={`${error && touched ? ' filed-error' : ''}${
        !error && touched && inputValue !== '' ? ' filed-success' : ''
      }`}
    >
      <Field
        name={name}
        id={name}
        type={type}
        autoComplete="false"
        readOnly
        value={value}
        placeholder={name}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </Field>
      {label && (
        <StyledField.FieldLabel htmlFor={name}>
          <span>{label}</span>
        </StyledField.FieldLabel>
      )}
      {error && touched && (
        <StyledField.FieldMsg role="alert">{error}</StyledField.FieldMsg>
      )}
    </StyledField.InputWrapper>
  ));
  return (
    <Styled.DatePicBox>
      <DatePicker
        locale="pl"
        dateFormat={dateFormat}
        popperPlacement={popperPlacement}
        selected={(field.value && new Date(field.value)) || null}
        onChange={val => {
          setFieldValue(
            field.name,
            moment(val).isValid() ? moment(val).format('YYYY-MM-DD') : ''
          );
        }}
        isClearable={disabled !== true}
        customInput={<CustomInput />}
      />
    </Styled.DatePicBox>
  );
};

export default CustomDatePicker;
