import React from 'react';
import * as Styled from './Departments.styles';
import { departmentName, userUnitKey } from '../../helpers/users';
import LabelTooltip from '../LabelTooltip/LabelTooltip';

const Departments = () => (
  <Styled.Departments>
    <ul className="departaments-list">
      {departmentName.map(asset => {
        return (
          <li className="key__item" key={asset.name}>
            <LabelTooltip asset={asset}>
              <span
                className={`key__color key__color--${userUnitKey(asset.name)}`}
                data-color-text={asset.name}
              />
            </LabelTooltip>
          </li>
        );
      })}
    </ul>
  </Styled.Departments>
);

export default Departments;
