import React from 'react';
import { CSSTransition } from 'react-transition-group';
import * as Styled from './RoomsList.styles';
import RoomTable from './RoomsTable/RoomTable';
import RoomRow from './RoomsTable/RoomRow';
import ConferenceRoomsView from '../RoomsViews/ConferenceRoomsView';
import HotdesksView from '../RoomsViews/HotdesksView';
import GarageView from '../RoomsViews/GarageView';
import { roomSorter, bookingFilter } from '../../../helpers/booking';
import Box from '../../../layouts/Box';
import { BoxHeader } from '../../../layouts/Box/Box.styles';
import Filters from '../../../components/Filters';
import AlertBlock from '../../../components/AlertBlock';
import TableContentLoader from '../../../components/ContentLoader/TableContentLoader';

const RoomsList = props => {
  const {
    rooms,
    bookingData,
    eventsData,
    onSetCapacity,
    onSetFeature,
    onSetRoom,
    toggledView,
    calendarDate,
    currentUser,
    setCurrentHour,
    setSelectedBooking,
    bookings,
    filters
  } = props;
  const conferences = roomSorter(rooms, '1');
  const hotdesks = roomSorter(rooms, '2');
  const garage = roomSorter(rooms, '3');

  return (
    <Styled.RoomsList>
      {!eventsData && (
        <Box header="Salki" className="table-box">
          <TableContentLoader />
        </Box>
      )}
      {!bookingData && (
        <>
          <Box header="Hotdeski" className="table-box">
            <TableContentLoader />
          </Box>
          <Box header="Garaż (Poziom: -2)" className="table-box">
            <TableContentLoader />
          </Box>
        </>
      )}

      {eventsData &&
        (parseInt(filters.floor, 10) === 1 || filters.floor === '') && (
          <Box className="table-box">
            <BoxHeader>
              <h3>Salki</h3>
              <Filters
                className="rooms-filters"
                onSetCapacity={onSetCapacity}
                onSetFeature={onSetFeature}
              />
            </BoxHeader>

            <div className="component-animation-wrapper">
              <CSSTransition
                in={!toggledView}
                classNames="component-animation"
                timeout={300}
                unmountOnExit
              >
                <>
                  {conferences.length === 0 && (
                    <AlertBlock warning inline icon="warning">
                      <p style={{ marginBottom: 0 }}>Brak wyników.</p>
                    </AlertBlock>
                  )}
                  {conferences.length !== 0 && (
                    <RoomTable>
                      {conferences.map(room => (
                        <RoomRow
                          key={room._id}
                          room={room}
                          bookings={bookingFilter(eventsData, room._id)}
                          calendarDate={calendarDate}
                          currentUser={currentUser}
                          setSelectedBooking={setSelectedBooking}
                          onSetRoom={onSetRoom}
                          setCurrentHour={setCurrentHour}
                        />
                      ))}
                    </RoomTable>
                  )}
                </>
              </CSSTransition>
            </div>

            <CSSTransition
              in={toggledView}
              classNames="component-animation"
              timeout={300}
              unmountOnExit
            >
              <ConferenceRoomsView
                onSetRoom={onSetRoom}
                bookings={eventsData}
                setSelectedBooking={setSelectedBooking}
                calendarDate={calendarDate}
                setCurrentHour={setCurrentHour}
                currentUser={currentUser}
                rooms={conferences}
              />
            </CSSTransition>
          </Box>
        )}

      {hotdesks.length !== 0 && (
        <Box header="Hotdeski" className="table-box">
          <div className="component-animation-wrapper">
            <CSSTransition
              in={!toggledView}
              classNames="component-animation"
              timeout={300}
              unmountOnExit
            >
              <RoomTable>
                {hotdesks.map(room => (
                  <RoomRow
                    key={room._id}
                    room={room}
                    bookings={bookingFilter(bookings, room._id)}
                    calendarDate={calendarDate}
                    currentUser={currentUser}
                    setSelectedBooking={setSelectedBooking}
                    onSetRoom={onSetRoom}
                    setCurrentHour={setCurrentHour}
                  />
                ))}
              </RoomTable>
            </CSSTransition>
          </div>

          <CSSTransition
            in={toggledView}
            classNames="component-animation"
            timeout={300}
            unmountOnExit
          >
            <HotdesksView
              onSetRoom={onSetRoom}
              bookings={bookings}
              setSelectedBooking={setSelectedBooking}
              calendarDate={calendarDate}
              setCurrentHour={setCurrentHour}
              currentUser={currentUser}
              rooms={hotdesks}
            />
          </CSSTransition>
        </Box>
      )}

      {garage.length !== 0 && (
        <Box header="Garaż (Poziom: -2)" className="table-box">
          <div className="component-animation-wrapper">
            <CSSTransition
              in={!toggledView}
              classNames="component-animation"
              timeout={300}
              unmountOnExit
            >
              <RoomTable>
                {garage.map(room => (
                  <RoomRow
                    key={room._id}
                    room={room}
                    bookings={bookingFilter(bookings, room._id)}
                    calendarDate={calendarDate}
                    currentUser={currentUser}
                    setSelectedBooking={setSelectedBooking}
                    onSetRoom={onSetRoom}
                    setCurrentHour={setCurrentHour}
                  />
                ))}
              </RoomTable>
            </CSSTransition>
          </div>

          <CSSTransition
            in={toggledView}
            classNames="component-animation"
            timeout={300}
            unmountOnExit
          >
            <GarageView
              onSetRoom={onSetRoom}
              bookings={bookings}
              setSelectedBooking={setSelectedBooking}
              calendarDate={calendarDate}
              setCurrentHour={setCurrentHour}
              currentUser={currentUser}
              rooms={garage}
            />
          </CSSTransition>
        </Box>
      )}
    </Styled.RoomsList>
  );
};

export default RoomsList;
