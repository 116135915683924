import React from 'react';
import * as Styled from './Logo.styles';
import logo from '../../assets/logo.png';

const Logo = props => {
  const { anchor } = props;

  return (
    <Styled.Logo>
      {!anchor && <img src={logo} alt="Plej" />}
      {anchor && (
        <Styled.LogoLink to="/">
          <img src={logo} alt="Plej" />
        </Styled.LogoLink>
      )}
    </Styled.Logo>
  );
};

export default Logo;
