import React from 'react';
import Layout from '../../layouts/Layout/Layout';
import * as Styled from './ErrorPage.styles';
import Icon from '../../components/Icon/Icon';

const ErrorPage = props => {
  const { currentUser, navToggler } = props;
  return (
    <Layout currentUser={currentUser} navToggler={navToggler}>
      <Styled.ErrorPage>
        <div>
          <Styled.ErrorPageIcon icon="error-404" removeInlineStyle />
          <Styled.ErrorPageHead>Taka strona nie istnieje</Styled.ErrorPageHead>
          <Styled.ErrorPageLink to="/">
            <Icon icon="chevron-left" size={12} />
            Wróć na stronę główną
          </Styled.ErrorPageLink>
        </div>
      </Styled.ErrorPage>
    </Layout>
  );
};

export default ErrorPage;
