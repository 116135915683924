import momentTimezone from 'moment-timezone';

const dateUTC = dataArray =>
  momentTimezone(dataArray)
    .tz('Europe/Warsaw')
    .toDate();

export const roomSorter = (roomList, floorNumber) => {
  const copiedList = roomList.slice(0);

  // filter list of rooms to those on the given floor
  const filteredList = copiedList.filter(room => room.floor === floorNumber);

  filteredList.sort((a, b) =>
    a.name.localeCompare(b.name, undefined, {
      numeric: true,
      sensitivity: 'base'
    })
  );

  // re-combine the sorted rooms, studios and others into a single array
  return filteredList;
};

export const bookingFilter = (bookingsList, roomId) => {
  if (!bookingsList) return [];

  const copiedList = bookingsList.slice(0);

  return copiedList.filter(
    booking => booking.roomId && booking.roomId._id === roomId
  );
};

export const currentFloorByUser = (bookingsList, floor, user) => {
  if (!bookingsList) return [];

  const date = new Date();
  date.setDate(date.getDate() - 1);
  const copiedList = [...bookingsList];

  return copiedList.filter(
    booking =>
      booking.roomId &&
      parseInt(booking.roomId.floor, 10) === parseInt(floor, 10) &&
      !booking.roomId.guest &&
      dateUTC(booking.bookingStart) >= date &&
      booking.user === user
  );
};
