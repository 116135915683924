import { Form, FieldArray } from 'formik';
import React from 'react';
import { isEmail } from 'react-multi-email';
import InputWrapper from '../../../../components/InputWrapper';
import { displayHourOptions } from '../../../../helpers/bookingForm';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import Button from '../../../../components/Button';
import * as Styled from './BookingForm.styles';

// TODO - allow access to form with no current room and display select with all rooms(required)

const BookingForm = ({
  errors,
  touched,
  values,
  formDisabled,
  calendarDate,
  currentDataDate,
  currentRoom,
  setFieldValue
}) => {
  return (
    <Form noValidate>
      <InputWrapper
        name="startTime"
        as="select"
        label="Pobyt od"
        error={errors.startTime}
        touched={touched.startTime}
        value={values.startTime}
        disabled={formDisabled}
      >
        <option value="">Wybierz</option>
        {displayHourOptions(calendarDate, currentDataDate).map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </InputWrapper>
      <InputWrapper
        name="endTime"
        as="select"
        label="Pobyt do"
        error={errors.endTime}
        touched={touched.endTime}
        value={values.endTime}
        disabled={formDisabled}
      >
        <option value="">Wybierz</option>
        {displayHourOptions(
          calendarDate,
          currentDataDate,
          values.startTime
        ).map(option => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })}
      </InputWrapper>
      {/* recurring available only for hotdesks */}
      {parseInt(currentRoom.floor, 10) === 2 && (
        <InputWrapper
          name="recurring"
          as="select"
          label="Powtórz"
          error={errors.recurring}
          touched={touched.recurring}
          value={values.recurring}
          disabled={formDisabled}
        >
          <option value="none">Nie powtarzaj</option>
          <option value="daily">Codziennie</option>
          <option value="weekly">Co tydzień</option>
          <option value="monthly">Co miesiąc</option>
        </InputWrapper>
      )}
      {parseInt(currentRoom.floor, 10) === 2 && values.recurring !== 'none' && (
        <CustomDatePicker
          name="recurringEndDate"
          type="text"
          label="Powtórz do"
          error={errors.recurringEndDate}
          touched={touched.recurringEndDate}
          value={values.recurringEndDate}
          dateFormat="yyyy-MM-dd"
          popperPlacement="top-start"
        />
      )}
      {parseInt(currentRoom.floor, 10) === 1 && (
        <InputWrapper
          name="purpose"
          label="Cel rezerwacji"
          error={errors.purpose}
          touched={touched.purpose}
          value={values.purpose}
          as="select"
          disabled={formDisabled}
        >
          <option value="">Wybierz</option>
          <option value="Praca">Praca</option>
          <option value="Spotkanie z klientem">Spotkanie z klientem</option>
          <option value="Spotkanie wewnętrzne">Spotkanie wewnętrzne</option>
          <option value="Rekrutacja">Rekrutacja</option>
          <option value="Onboarding">Onboarding</option>
        </InputWrapper>
      )}
      <InputWrapper
        name="description"
        component="textarea"
        className="textarea-wrapper"
        label="Dodatkowy opis"
        error={errors.description}
        touched={touched.description}
        value={values.description}
        disabled={formDisabled}
      />
      {parseInt(currentRoom.floor, 10) === 1 && (
        // <FieldArray
        //   name="attendees"
        //   render={arrayHelpers => (
        //     <>
        //       {values.attendees &&
        //         values.attendees.length > 0 &&
        //         values.attendees.map((attendee, index) => (
        //           // eslint-disable-next-line react/no-array-index-key
        //           <div style={{ position: 'relative' }} key={index}>
        //             <InputWrapper
        //               name={`attendees.${index}`}
        //               label="E-mail uczestnika"
        //               error={
        //                 (errors.attendees && errors.attendees[index]) || null
        //               }
        //               touched={
        //                 (touched.attendees && touched.attendees[index]) || null
        //               }
        //               value={values.attendees[index]}
        //               disabled={formDisabled}
        //             />
        //             <RemoveButton
        //               type="button"
        //               aria-label="Usuń uczestnika"
        //               onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
        //             >
        //               &#xD7;
        //             </RemoveButton>
        //           </div>
        //         ))}
        //       <Button
        //         primary
        //         type="button"
        //         onClick={() => arrayHelpers.push('')}
        //       >
        //         Zaproś{values.attendees.length ? ' kolejnego' : ''} uczestnika
        //       </Button>
        //     </>
        //   )}
        // />
        <FieldArray
          name="attendees"
          render={() => (
            <Styled.MultiEmail
              placeholder="Dodaj e-maile uczestników; Oddziel je przecinkiem lub enterem."
              emails={values.attendees}
              onChange={emails => {
                setFieldValue('attendees', emails);
              }}
              validateEmail={email => isEmail(email)}
              getLabel={(email, index, removeEmail) => (
                <div data-tag="" key={index}>
                  <button
                    data-tag-handle=""
                    type="button"
                    onClick={() => removeEmail(index)}
                  >
                    {email}
                  </button>
                </div>
              )}
            />
          )}
        />
      )}
      <InputWrapper
        name="presence"
        className="checkbox-wrapper"
        label="Zgłoś obecność tego dnia"
        error={errors.presence}
        touched={touched.presence}
        value={values.presence}
        type="checkbox"
        disabled={formDisabled || parseInt(currentRoom.floor, 10) !== 1}
      />
      <div className="text-right">
        <Button
          primary
          large
          text="Rezerwuj"
          type="submit"
          disabled={formDisabled}
        />
      </div>
    </Form>
  );
};

export default BookingForm;
