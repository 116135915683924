import React from 'react';
import Layout from '../../layouts/Layout/Layout';
import Box from '../../layouts/Box';
import Table from '../../components/Typography/Table';
import { userUnitKey } from '../../helpers/users';
import * as Styled from './Account.styles';

const Account = props => {
  const { currentUser, navToggler } = props;

  const displayRole = () => {
    switch (props.currentUser.role) {
      case 90:
        return 'Zarząd';
      case 99:
        return 'Administrator';
      default:
        return 'Pracownik';
    }
  };

  return (
    <Layout
      currentUser={currentUser}
      navToggler={navToggler}
      title="Konto użytkownika"
      contentClass="main-user-account"
    >
      <Styled.Account>
        <Box header="Konto zarejestrowane na Active Directory">
          <Table>
            <thead>
              <tr>
                <th>Imie i&nbsp;nazwisko</th>
                <th>E-mail</th>
                <th>Dział</th>
                <th>ID konta</th>
                <th>Typ konta</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{currentUser.name}</td>
                <td>{currentUser.email}</td>
                <td>
                  {currentUser.department}{' '}
                  <span
                    className={`department-badge department--${userUnitKey(
                      currentUser.department
                    )}`}
                  />
                </td>
                <td>{currentUser.id}</td>
                <td>{displayRole()}</td>
              </tr>
            </tbody>
          </Table>
        </Box>
      </Styled.Account>
    </Layout>
  );
};

export default Account;
