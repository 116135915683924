import React from 'react';
import PageHeader from '../PageHeader';
import { H2 } from '../../components/Typography/Typography.styles';
import * as Styled from './Layout.styles';

const Layout = ({ title, children }) => (
  <Styled.Layout>
    {title && (
      <PageHeader>
        <H2>{title}</H2>
      </PageHeader>
    )}
    {children}
  </Styled.Layout>
);

export default Layout;
