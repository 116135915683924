import React from 'react';
import * as Styled from './Spinner.styles';

const Spinner = () => {
  return (
    <Styled.SpinnerLayer>
      <Styled.SpinnerWrapper>
        <Styled.Spinner />
      </Styled.SpinnerWrapper>
    </Styled.SpinnerLayer>
  );
};

export default Spinner;
