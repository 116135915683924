import styled from 'styled-components';

const PendingBookings = styled.article`
  width: 100%;

  .table-wrapper {
    overflow-x: auto;
  }

  .small {
    font-size: 80%;
  }
`;

export { PendingBookings };
