import React from 'react';
import Table from '../../../components/Typography/Table';

const MyBookingsTable = ({ children, bookingStatus }) => (
  <div className="table-wrapper">
    <Table>
      <thead>
        <tr>
          <th>Data</th>
          <th>Pomieszczenie</th>
          {bookingStatus !== 'old' && <th>&nbsp;</th>}
          <th>Cel wizyty</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </Table>
  </div>
);

export default MyBookingsTable;
