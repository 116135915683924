import styled from 'styled-components';

const GarageView = styled.article`
  width: 100%;

  .wall,
  .pillar {
    position: relative;

    &::before,
    &::after {
      position: absolute;
      width: 6px;
      background-color: ${props => props.theme.colors.white};
      content: '';
      z-index: 10;
    }
  }

  .wall-left {
    &::before {
      display: block;

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        left: 0;
        bottom: 0;
        width: 90%;
        height: 4px;
      }

      @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
        left: 0;
        top: 0;
        width: 6px;
        height: 90%;
      }
    }
  }

  .wall-right {
    &::after {
      display: block;

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        left: 0;
        top: 0;
        width: 90%;
        height: 6px;
      }

      @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
        right: 0;
        top: 0;
        width: 6px;
        height: 90%;
      }
    }
  }

  .pillar-left {
    &::before {
      display: block;

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        left: 70%;
        bottom: 0;
        width: 10%;
        height: 6px;
      }

      @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
        left: 0;
        top: 70%;
        width: 6px;
        height: 10%;
      }
    }
  }

  .pillar-right {
    &::after {
      display: block;

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        left: 70%;
        top: 0;
        width: 10%;
        height: 6px;
      }

      @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
        right: 0;
        top: 70%;
        width: 6px;
        height: 10%;
      }
    }
  }

  .garage-wrapper {
    border-radius: 10px;
  }

  .garage {
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: ${props => props.theme.colors.garageFloor};

    @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
      flex-direction: column;
    }
  }

  .park-spaces-group {
    display: flex;
    align-items: stretch;
    height: 100%;
    padding: 80px 0 0;
    flex-direction: column;
    list-style-type: none;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      min-width: 140px;
      flex-basis: 140px;
    }

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      min-width: 160px;
      flex-basis: 160px;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
      width: 100%;
      min-width: auto;
      height: 160px;
      padding: 0 10% 0 0;
      flex-direction: row-reverse;
    }
  }

  .park-space-wrapper {
    flex-basis: 100%;
    display: block;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      padding-right: 8px;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
      padding-bottom: 8px;
    }

    &:first-child {
      .park-space-content {
        @media (max-width: ${props => props.theme.breakpoints.md}) {
          border-top-width: 6px;
        }

        @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
          border-right-width: 6px;
        }

        &::after {
          @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
            right: -6px;
            width: 15px;
          }
        }

        &::before {
          @media (max-width: ${props => props.theme.breakpoints.md}) {
            top: -6px;
            height: 15px;
          }
        }
      }
    }
  }

  .park-space-content {
    position: relative;
    display: block;
    height: 100%;
    padding: 8px;
    border-color: ${props => props.theme.colors.garageLine};
    border-width: 0 3px;
    border-style: solid;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      border-color: ${props => props.theme.colors.garageLine};
      border-width: 3px 0;
      border-style: solid;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
      border-color: ${props => props.theme.colors.garageLine};
      border-width: 0 3px;
      border-style: solid;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
      &:hover {
        .tooltip {
          visibility: visible;
          opacity: 1;
        }

        &.plej-space {
          .park-space {
            background-color: ${props => props.theme.colors.darkenGray};
          }
        }
      }
    }

    &::before {
      display: block;
      position: absolute;
      content: '';
      background-color: ${props => props.theme.colors.garageLine};

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        right: -6px;
        top: -3px;
        width: 6px;
        height: 12px;
      }

      @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
        bottom: -6px;
        left: -3px;
        width: 12px;
        height: 6px;
      }
    }

    &::after {
      display: block;
      position: absolute;
      content: '';
      background-color: ${props => props.theme.colors.garageLine};

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        right: -6px;
        bottom: -3px;
        width: 6px;
        height: 12px;
      }

      @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
        right: -3px;
        bottom: -6px;
        width: 12px;
        height: 6px;
      }
    }

    &.plej-space {
      .park-space {
        cursor: pointer;
        background-color: ${props => props.theme.colors.white};

        @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
          padding-top: 20px;
        }

        &::before {
          color: ${props => props.theme.colors.primary};
        }
      }

      .logo-plej {
        width: 26px;

        @media (max-width: ${props => props.theme.breakpoints.md}) {
          margin-right: 18px;
        }

        @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
          margin-bottom: 68px;
        }
      }

      .park-number {
        color: ${props => props.theme.colors.primary};

        @media (max-width: ${props => props.theme.breakpoints.md}) {
          margin-top: 4px;
        }
      }

      &.guest-space {
        .park-space {
          padding-top: 8px;
          color: ${props => props.theme.colors.black};
        }

        .guest-icon {
          @media (max-width: ${props => props.theme.breakpoints.md}) {
            margin-right: 18px;
          }

          @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
            margin-bottom: 66px;
          }
        }
      }
    }

    &.unavailable {
      .park-space {
        background-color: #aaa;
      }
    }
  }

  .park-space {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    border: none;
    font-family: sans-serif;
    font-size: 32px;
    color: ${props => props.theme.colors.white};
    line-height: 1;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-weight: 700;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      flex-direction: row;
      justify-content: flex-end;
      min-height: 50px;
      padding: 8px 12px 8px 40px;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
      flex-direction: column;
      padding: 104px 0 0;
      align-items: center;
      justify-content: flex-start;
    }

    &::before {
      position: absolute;
      display: block;
      font-size: 36px;
      color: ${props => props.theme.colors.white};
      content: 'P';

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        top: 10px;
        left: 15px;
      }

      @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
        top: 55px;
        left: 0;
        width: 100%;
        text-align: center;
      }
    }

    .tooltip {
      color: ${props => props.theme.colors.black};
    }

    .park-state {
      display: none;
      margin-top: 8px;
      font-size: 16px;
      text-transform: uppercase;

      @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
        margin-top: 20px;
      }
    }

    .park-number {
      display: block;
      font-size: 18px;
    }
  }

  .way {
    position: relative;
    width: 120px;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      flex-basis: 100%;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAA/AQMAAAD5dytuAAAABlBMVEUAAAD84CUMwxTQAAAAAXRSTlMAQObYZgAAAA5JREFUCNdjIA38oS4EACfYJ2EIdXS5AAAAAElFTkSuQmCC');
      background-position: 50% 50%;
      background-size: auto;
      background-repeat: repeat-y;
    }

    @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
      width: 100%;
      height: 200px;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAAGAQMAAABO5NT5AAAABlBMVEUAAAD84CUMwxTQAAAAAXRSTlMAQObYZgAAABBJREFUCNdj+A8CDEBACgMAVAwd4/wpGskAAAAASUVORK5CYII=');
      background-position: 50% 50%;
      background-size: auto;
      background-repeat: repeat-x;
    }

    &::before {
      position: absolute;
      display: block;
      color: ${props => props.theme.colors.garageLine};
      content: '\\0279E';
      top: 15px;
      left: 0;
      width: 100%;
      text-align: center;
      line-height: 1;

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        font-size: 40px;
        top: 50%;
        left: 0;
        width: 50%;
        transform: rotate(90deg);
      }

      @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
        font-size: 60px;
        top: 15px;
        left: 0;
        width: 100%;
        transform: rotate(180deg);
      }
    }

    &::after {
      position: absolute;
      display: block;
      color: ${props => props.theme.colors.garageLine};
      content: '\\0279E';
      left: 0;
      width: 100%;
      text-align: center;
      line-height: 1;

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        font-size: 40px;
        top: 50%;
        left: 50%;
        width: 50%;
        transform: rotate(-90deg);
      }

      @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
        font-size: 60px;
        top: auto;
        bottom: 15px;
        left: 0;
        width: 100%;
      }
    }

    .exit {
      width: calc(10% + 2px);
      height: 206px;
      position: absolute;
      right: 0;
      top: 0;
      border: 2px solid ${props => props.theme.colors.garageLine};
      color: 2px solid ${props => props.theme.colors.white};
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: ${props => props.theme.colors.garageFloor};

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        width: calc(100% + 12px);
        height: 82px;
        border-left: 6px solid ${props => props.theme.colors.garageLine};
        left: -8px;
      }

      @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
        width: 10%;
        height: calc(100% + 12px);
        border-top: 6px solid ${props => props.theme.colors.garageLine};
        top: -8px;
      }

      &::before {
        @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
          display: block;
          width: 100%;
          height: 50%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: ${props => props.theme.colors.garageLine};
          content: '';
          clip-path: polygon(
            0 100%,
            0 calc(100% - 1px),
            calc(100% - 1px) 0,
            100% 0,
            100% 1px,
            1px 100%
          );
        }
      }

      &::after {
        @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
          display: block;
          width: 100%;
          height: 50%;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: ${props => props.theme.colors.garageLine};
          content: '';
          clip-path: polygon(
            0 0,
            1px 0,
            100% calc(100% - 1px),
            100% 100%,
            calc(100% - 1px) 100%,
            0 1px
          );
        }
      }

      .exit-content {
        display: block;
        color: ${props => props.theme.colors.white};
        text-transform: uppercase;
        text-align: center;

        @media (max-width: ${props => props.theme.breakpoints.md}) {
          width: 100%;
          padding-bottom: 10px;
        }

        @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
          width: max-content;
          padding-bottom: 20px;
          transform: rotate(90deg);
        }

        @media (min-width: ${props => props.theme.breakpoints.xlUp}) {
          padding-bottom: 40px;
        }
      }
    }
  }

  .boxes {
    position: relative;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      flex-basis: 64px;
      min-width: 64px;
      border-left: 6px solid ${props => props.theme.colors.garageLine};
    }

    @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
      width: 100%;
      height: 80px;
      border-top: 6px solid ${props => props.theme.colors.garageLine};
    }

    .stairs {
      position: absolute;
      top: 0;
      width: 20%;
      height: 80px;
      color: ${props => props.theme.colors.white};
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 120px;

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        right: -10px;
        width: 65px;
        height: 140px;
        padding: 0 80px 0 0;
        transform: rotate(-90deg);
      }

      @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
        right: 0;
        width: 20%;
        height: 80px;
      }

      &::after {
        position: absolute;
        display: block;
        font-size: 40px;
        color: ${props => props.theme.colors.white};
        content: '\\0279E';
        text-align: center;
        line-height: 1;

        @media (max-width: ${props => props.theme.breakpoints.md}) {
          top: 60px;
          right: 0;
          height: 80px;
        }

        @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
          top: 20px;
          right: -20px;
          width: 100%;
        }
      }
    }
  }
`;

export { GarageView };
