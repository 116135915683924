import styled from 'styled-components';

const MyBookingsRow = styled.tr`
  p {
    margin-bottom: 5px;
  }

  .small {
    font-size: 80%;
  }

  .booking-row-header {
    width: min-content;
    font-weight: 400;
    text-align: left;

    span {
      white-space: nowrap;
    }
  }

  td {
    vertical-align: middle;
  }

  .action {
    text-align: center;
    vertical-align: middle;
  }

  &.pending {
    background-color: lightyellow !important;
  }

  &.rejected {
    background-color: #ff8585 !important;
  }
`;

export { MyBookingsRow };
