import styled from 'styled-components';

const Sidebar = styled.aside`
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 100%;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    width: 30%;
    margin-right: 20px;
  }

  &.sidebar-sticky {
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      position: sticky;
      top: 10px;
      height: max-content;
    }
  }
`;

export { Sidebar };
