import React from 'react';
import * as Styled from './LabelTooltip.styles';

const LabelTooltip = props => {
  const { asset, children } = props;

  return (
    <Styled.LabelTooltip data-label-info={asset.name}>
      {children}
    </Styled.LabelTooltip>
  );
};

export default LabelTooltip;
