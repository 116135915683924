import styled from 'styled-components';

const ColumnCell = styled.td`
  position: relative;

  .table__link--available,
  .table__cell--booked {
    position: absolute;
    top: 0;
    left: 0;
    right: -1px;
    bottom: 0;
    display: block;
    padding: 5px;
    cursor: pointer;
    z-index: 1;
  }

  .table__link--available:hover {
    background-color: ${props => props.theme.colors.primary};
  }

  .table__cell--booked {
    top: 0;
    bottom: 0;
    left: calc(50% - 30px);
    right: auto;
    width: 60px;
    background-color: ${props => props.theme.department.other};

    &.my__booking {
      background-image: repeating-linear-gradient(
        20deg,
        ${props => props.theme.colors.transparent},
        ${props => props.theme.colors.transparent} 33.3333%,
        ${props => props.theme.colors.translucent} 33.3333%,
        ${props => props.theme.colors.translucent} 66.6667%
      );
    }

    &.status--pending {
      opacity: 0.5;
    }

    &.table__cell {
      &--finance {
        background-color: ${props => props.theme.department.finanse};
      }
      &--hr {
        background-color: ${props => props.theme.department.hr};
      }
      &--legaloperations {
        background-color: ${props => props.theme.department.legaloperations};
      }
      &--kreacjaeventowa {
        background-color: ${props => props.theme.department.kreacjaeventowa};
      }
      &--events {
        background-color: ${props => props.theme.department.eventy};
      }
      &--clientservice {
        background-color: ${props => props.theme.department.clientservice};
      }
      &--strategy {
        background-color: ${props => props.theme.department.strategia};
      }
      &--kreacja,
      &--creation,
      &--creationzdalnie {
        background-color: ${props => props.theme.department.kreacja};
      }
      &--socialmedia {
        background-color: ${props => props.theme.department.socialmedia};
      }
      &--development {
        background-color: ${props => props.theme.department.development};
      }
      &--pmo {
        background-color: ${props => props.theme.department.pmo};
      }
      &--uxui,
      &--ux,
      &--ui {
        background-color: ${props => props.theme.department.uxui};
      }
      &--publicrelations {
        background-color: ${props => props.theme.department.publicrelations};
      }
      &--businessdevelopment {
        background-color: ${props =>
          props.theme.department.businessdevelopment};
      }
      &--zarzad,
      &--boa {
        background-color: ${props => props.theme.department.zarzad};
      }
    }

    &.table__cell--second-half-hour {
      border-top-left-radius: ${props => props.theme.borderRadius};
      border-top-right-radius: ${props => props.theme.borderRadius};

      &.my__booking {
        background-image: repeating-linear-gradient(
          20deg,
          ${props => props.theme.colors.transparent},
          ${props => props.theme.colors.transparent} 60%,
          ${props => props.theme.colors.translucent} 60%,
          ${props => props.theme.colors.translucent} 100%
        );
      }
    }

    &.table__cell--first-half-hour {
      border-bottom-left-radius: ${props => props.theme.borderRadius};
      border-bottom-right-radius: ${props => props.theme.borderRadius};

      &.my__booking {
        background-image: repeating-linear-gradient(
          20deg,
          ${props => props.theme.colors.translucent},
          ${props => props.theme.colors.translucent} 40%,
          ${props => props.theme.colors.transparent} 40%,
          ${props => props.theme.colors.transparent} 100%
        );
      }
    }
  }

  .table__cell--start {
    border-radius: ${props => props.theme.borderRadius}
      ${props => props.theme.borderRadius} 0 0;
    top: 2px;

    &.table__cell--end {
      height: calc(100% - 4px);
      border-radius: ${props => props.theme.borderRadius};
    }
  }

  .table__cell--end {
    border-radius: 0 0 ${props => props.theme.borderRadius}
      ${props => props.theme.borderRadius};
    bottom: 2px;
  }

  .table__cell--half {
    height: calc(50% - 3px);

    &.table__cell--start.table__cell--end {
      height: calc(50% - 4px);
      border-radius: ${props => props.theme.borderRadius};
    }
  }

  .table__cell--second-half-hour {
    bottom: 2;
    top: auto;

    &.table__cell--end {
      bottom: 2px;
    }
  }

  .table__cell--first-half-hour {
    top: 2;
    bottom: auto;

    &.table__cell--start {
      top: 2px;
    }
  }
`;

export { ColumnCell };
