import styled, { css } from 'styled-components';

const AlertBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 10px 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
  border-radius: ${props => props.theme.borderRadius};
  
  > svg {
    flex-shrink: 0;
    margin-right: 10px;
    width: 20px;
    fill: currentColor;
  }

  ${props =>
    props.large &&
    css`
      > svg {
        width: 40px;
      }
    `}
  
  ${props =>
    props.inline &&
    css`
      display: inline-flex;
    `}
  
  ${props =>
    props.info &&
    css`
      background: linear-gradient(
        90deg,
        ${props.theme.colors.semiBlue} 15px,
        ${props.theme.colors.lightBlue} 0
      );
      color: ${props.theme.colors.darkenBlue};
    `}

  ${props =>
    props.danger &&
    css`
      background: linear-gradient(
        90deg,
        ${props.theme.colors.semiRed} 15px,
        ${props.theme.colors.lightRed} 0
      );
      color: ${props.theme.colors.darkenRed};
    `}

  ${props =>
    props.warning &&
    css`
      background: linear-gradient(
        90deg,
        ${props.theme.colors.semiYellow} 15px,
        ${props.theme.colors.lightYellow} 0
      );
      color: ${props.theme.colors.darkenYellow};
    `}

  ${props =>
    props.success &&
    css`
      background: linear-gradient(
        90deg,
        ${props.theme.colors.semiGreen} 15px,
        ${props.theme.colors.lightGreen} 0
      );
      color: ${props.theme.colors.darkenGreen};
    `}
`;

const AlertBlockBox = styled.div`
  flex-grow: 1;
`;

export { AlertBlock, AlertBlockBox };
