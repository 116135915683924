import React from 'react';
import * as Styled from './BookingTooltip.styles';
import { bookingFilter } from '../../../../helpers/booking';
import BookingTable from '../../../CreateBooking/BookingTable';

const BookingTooltip = props => {
  const {
    bookings,
    calendarDate,
    currentUser,
    onSetRoom,
    room,
    setCurrentHour,
    setSelectedBooking
  } = props;
  return (
    <Styled.BookingTooltip>
      <div className="tooltip">
        <BookingTable
          bookings={bookingFilter(bookings, room._id)}
          room={room}
          calendarDate={calendarDate}
          setSelectedBooking={setSelectedBooking}
          onSetRoom={onSetRoom}
          setCurrentHour={setCurrentHour}
          currentUser={currentUser}
        />
      </div>
    </Styled.BookingTooltip>
  );
};

export default BookingTooltip;
