import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Layout from '../../layouts/Layout/Layout';
import Content from '../../layouts/Content';
import Sidebar from '../../layouts/Sidebar';
import 'moment/locale/pl';
import Calendar from '../../components/Calendar';
import Box from '../../layouts/Box';
import Departments from '../../components/Departments';
import RoomsList from './RoomsList';
import BookingModal from '../../components/BookingModal';
import {
  onFilterByCapacity,
  onFilterRoomsByFeature,
  onFilterByFloor
} from '../../helpers/filters';
import { getRoomsList } from '../../api/rooms';
import { listBookings } from '../../api/booking';
import { calendarEventsIntoBookings, listEvents } from '../../api/calendar';
import TopbarFilters from '../../components/TopbarFilters';

const Dashboard = props => {
  const {
    setToggledView,
    toggledView,
    navToggler,
    bookingData,
    setBookingData,
    eventsData,
    setEventsData,
    setSelectedBooking,
    calendarDate,
    setCalendarDate,
    onSetRoom,
    roomData,
    setRoomData,
    setCurrentHour,
    setCurrentDataDate,
    currentUser,
    selectedBooking,
    onCloseBooking,
    onDeleteBooking,
    currentDataDate
  } = props;
  const [filters, setFilters] = useState({
    floor: '',
    features: [],
    capacity: []
  });
  const [filteredRooms, setFilteredRooms] = useState([]);

  const processEvents = responseData => {
    let allBookingEvents = eventsData || [];

    responseData.items.forEach((roomEventsData, index) => {
      const bookingEvents = calendarEventsIntoBookings(
        roomEventsData.data.value,
        responseData.rooms[index],
        allBookingEvents
      );

      allBookingEvents = allBookingEvents.concat(bookingEvents);
    });

    setEventsData(allBookingEvents);
  };

  // load all necessary data
  useEffect(() => {
    // load data only if its not already loaded
    if (roomData.length === 0) {
      getRoomsList()
        .then(rooms => {
          if (!rooms || rooms.length === 0) {
            console.error('Error loading room data');
            return;
          }

          listEvents(currentUser, rooms).then(response =>
            processEvents(response)
          );

          // add assets array for filtering by feature
          rooms.forEach(room => {
            const allFeatures = [];

            if (room.features)
              room.features.forEach(feature => {
                allFeatures.push(feature.key);
              });

            // eslint-disable-next-line no-param-reassign
            room.assets = allFeatures;
          });
          setRoomData(rooms);
          setFilteredRooms(rooms);
        })
        .catch(error => console.error('Error loading room data', error));

      listBookings()
        .then(response => {
          setBookingData(response.bookings);
          setCurrentDataDate(response.currentDate);
        })
        .catch(error => console.error('Error loading booking data', error));
    }

    if (roomData.length && currentDataDate) {
      listBookings({
        fromDate: currentDataDate,
        user: currentUser.id
      })
        .then(response => {
          if (response.bookings.length) {
            let updatedBookings = [...bookingData];
            const newIds = [];

            response.bookings.forEach(b => newIds.push(b._id));

            // remove bookings with new version
            updatedBookings = updatedBookings.filter(
              booking => !newIds.includes(booking._id)
            );

            updatedBookings = updatedBookings.concat(response.bookings);
            setBookingData(updatedBookings);
          }

          setCurrentDataDate(response.currentDate);
        })
        .catch(error => console.error('Error loading booking data', error));

      listEvents(currentUser, roomData, currentDataDate).then(response =>
        processEvents(response)
      );
    }
  }, []);

  const filterRooms = () => {
    let filteredData = [];

    if (filteredRooms) {
      filteredData = onFilterByFloor(filters.floor, roomData);
      filteredData = onFilterByCapacity(filters.capacity, filteredData);
      filteredData = onFilterRoomsByFeature(filters.features, filteredData);
    }

    setFilteredRooms(filteredData);

    // setFilteredRooms(rooms => rooms);
  };

  useEffect(() => {
    if (roomData.length) filterRooms();
  }, [filters]);

  const onSetFloor = floor => {
    const newFilters = { ...filters };
    newFilters.floor = floor;
    setFilters(newFilters);
  };

  const onSetCapacity = capacity => {
    const cap = parseInt(capacity, 10);

    const newFilters = { ...filters };
    if (newFilters.capacity.includes(cap)) {
      const indexToRemove = newFilters.capacity.indexOf(cap);
      newFilters.capacity.splice(indexToRemove, 1);
    } else {
      newFilters.capacity.push(cap);
    }
    setFilters(newFilters);
  };

  const onSetFeature = features => {
    const newFilters = { ...filters };
    if (newFilters.features.includes(features)) {
      const indexToRemove = newFilters.features.indexOf(features);
      newFilters.features.splice(indexToRemove, 1);
    } else {
      newFilters.features.push(features);
    }
    setFilters(newFilters);
  };

  const removeRejectedBookings = bookings =>
    bookings && bookings.filter(booking => booking.status !== 2);

  return (
    <Layout
      currentUser={currentUser}
      navToggler={navToggler}
      title={`Rezerwacja zasobów (${moment(calendarDate).format(
        'D MMMM YYYY'
      )})`}
    >
      <Sidebar className="sidebar-sticky">
        <Calendar
          calendarDate={calendarDate}
          setCalendarDate={setCalendarDate}
          dateFormat="YYYY-MM-DD"
          timeFormat={false}
          input
          value={moment(calendarDate)}
          renderInput={() => (
            <div className="calendar-aside">
              {moment(calendarDate).format('dddd, [\n] LL')}
            </div>
          )}
          utc={false}
          locale="pl"
          onChange={event => setCalendarDate(event._d)}
        />
        <Box header="Kolory działów">
          <Departments />
        </Box>
      </Sidebar>
      <Content>
        <TopbarFilters
          filters={filters}
          onSetFloor={onSetFloor}
          setToggledView={setToggledView}
          toggledView={toggledView}
        />

        <RoomsList
          bookingData={bookingData}
          toggledView={toggledView}
          rooms={filteredRooms}
          eventsData={eventsData}
          bookings={removeRejectedBookings(bookingData)}
          setSelectedBooking={setSelectedBooking}
          calendarDate={calendarDate}
          onSetRoom={onSetRoom}
          setCurrentHour={setCurrentHour}
          currentUser={currentUser}
          filters={filters}
          onSetFloor={onSetFloor}
          onSetCapacity={onSetCapacity}
          onSetFeature={onSetFeature}
        />
      </Content>
      <BookingModal
        selectedBooking={selectedBooking}
        onCloseBooking={onCloseBooking}
        onDeleteBooking={onDeleteBooking}
        currentDataDate={currentDataDate}
        currentUser={currentUser}
      />
    </Layout>
  );
};

export default Dashboard;
