import styled, { css } from 'styled-components';

// Define header styles
const headingStyle = css`
  margin: 20px 0 10px;
  font-weight: 300;
`;

// Styled typography
const H1 = styled.h1`
  ${headingStyle}

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 42px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    font-size: 54px;
  }
`;

const H2 = styled.h2`
  ${headingStyle}

  @media(max-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    font-size: 36px;
  }
`;

const H3 = styled.h3`
  ${headingStyle}

  @media(max-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 20px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    font-size: 24px;
  }
`;

const H4 = styled.h4`
  ${headingStyle}

  @media(max-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 18px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    font-size: 20px;
  }
`;

const H5 = styled.h5`
  ${headingStyle}

  @media(max-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 12px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    font-size: 14px;
  }
`;

const H6 = styled.h6`
  ${headingStyle}

  @media(max-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 10px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    font-size: 12px;
  }
`;

// Define typography elements styles
const Text = styled.p`
  font-size: 16px;
`;

const Ul = styled.ul`
  padding-left: 30px;
`;

const Li = styled.li`
  list-style: circle;
`;

const Small = styled.small`
  font-size: 80%;
`;

export { H1, H2, H3, H4, H5, H6, Text, Small, Ul, Li };
