import axios from 'axios';
import moment from 'moment-timezone';
import { reportAttendance } from './presence';

export async function listEvents(currentUser, rooms, currentDataDate = false) {
  const aadItems = {
    rooms: [],
    promises: []
  };
  const monthAgo = new Date();
  const futureDate = new Date();
  let token;

  monthAgo.setDate(monthAgo.getDate() - 30);
  futureDate.setDate(futureDate.getDate() + 90);

  const startFormatted = moment
    .tz(monthAgo, 'Europe/Warsaw')
    .format('Y-MM-DDTHH:mm:ss');
  const endFormatted = moment
    .tz(futureDate, 'Europe/Warsaw')
    .format('Y-MM-DDTHH:mm:ss');

  await currentUser.jwtAccessToken().then(data => {
    token = data.accessToken.toString();
  });

  await rooms.forEach(room => {
    if (room.aadId) {
      // const getEvents = `https://graph.microsoft.com/v1.0/users/${bazylia}/calendar/events?$top=100`;
      const getCalendarView = `https://graph.microsoft.com/v1.0/users/${
        room.aadId
      }/calendar/calendarView?endDateTime=${endFormatted}&startDateTime=${currentDataDate ||
        startFormatted}&createdDateTime=${startFormatted}&orderby=start/dateTime&top=999`;

      aadItems.rooms.push(room);
      aadItems.promises.push(
        axios.get(getCalendarView, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      );
    }
  });

  return Promise.all(aadItems.promises).then(response => {
    return {
      items: response,
      rooms: aadItems.rooms
    };
  });
}

export function calendarEventsIntoBookings(
  events,
  roomData,
  currentEvents,
  offsetUpdate = true
) {
  const bookings = [];
  const offset = new Date().getTimezoneOffset();

  events.forEach(event => {
    let duplicated = false;

    if (currentEvents.length)
      currentEvents.forEach(ce => {
        if (ce._id === event.id) {
          duplicated = true;
        }
      });

    let start = new Date(event.start.dateTime);
    let end = new Date(event.end.dateTime);

    const diff = end.getTime() - start.getTime();

    if (offsetUpdate) {
      start.setTime(start.getTime() - offset * 60 * 1000);
      end.setTime(end.getTime() - offset * 60 * 1000);
    }

    const startHour = start.getHours();
    const startHourHalf = start.getMinutes();

    start = moment(start).format('Y-MM-DDTHH:mm:ss');
    end = moment(end).format('Y-MM-DDTHH:mm:ss');

    const booking = {
      _id: event.id,
      aadId: event.id,
      bookingDate: event.createdDateTime,
      bookingStart: start,
      bookingEnd: end,
      eventStart: event.start.dateTime,
      eventEnd: event.end.dateTime,
      businessUnit: '',
      description: '',
      duration: diff / (1000 * 3600),
      email: event.organizer.emailAddress.address,
      name: event.organizer.emailAddress.name,
      purpose: event.subject,
      roomId: roomData,
      startHour: startHour + (startHourHalf !== 0 ? 0.5 : 0),
      status: 3
    };

    if (!duplicated) bookings.push(booking);
  });

  return bookings;
}

export function addEvent(
  currentUser,
  calendarDate,
  start,
  end,
  room,
  name,
  recurringType,
  recurringEndDate,
  attendees,
  presence
) {
  const dateFormat = date =>
    moment.tz(date, 'Europe/Warsaw').format('Y-MM-DDTHH:mm:ss');

  const roomEmail = `${room.name}@plej.pl`;
  const attendeesArray = [
    {
      emailAddress: {
        address: roomEmail
      }
    }
  ];

  attendees.forEach(attendee => {
    attendeesArray.push({
      emailAddress: {
        address: attendee
      }
    });
  });

  const startDate = new Date(calendarDate);
  const endDate = new Date(calendarDate);
  const startSheduleDate = new Date(calendarDate);
  const endSheduleDate = new Date(calendarDate);

  startDate.setHours(start[3], start[4], 0);
  endDate.setHours(end[3], end[4], 0);
  startSheduleDate.setHours(start[3] - 1, start[4] + 5, 0);
  endSheduleDate.setHours(end[3] - 1, end[4] - 5, 0);

  let recurrence = null;

  if (recurringType !== 'none')
    recurrence = {
      pattern: {
        type: recurringType,
        interval: 1
      },
      range: {
        type: 'endDate',
        startDate: moment.tz(startDate, 'Europe/Warsaw').format('Y-MM-DD'),
        endDate: moment
          .tz(new Date(recurringEndDate), 'Europe/Warsaw')
          .format('Y-MM-DD')
      }
    };

  if (presence) reportAttendance(currentUser, start, end);

  const getShedule = response => {
    const token = response.accessToken.toString();

    return axios
      .post(
        `https://graph.microsoft.com/v1.0/users/${room.aadId}/calendar/getSchedule`,
        {
          schedules: [roomEmail],
          startTime: {
            dateTime: dateFormat(startSheduleDate),
            timeZone: 'UTC'
          },
          endTime: {
            dateTime: dateFormat(endSheduleDate),
            timeZone: 'UTC'
          },
          availabilityViewInterval: 5
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            responseType: 'application/json'
          }
        }
      )
      .then(res => {
        if (res.data.value) {
          if (res.data.value[0].scheduleItems.length === 0) return { token };

          throw new Error(
            'Błąd. W systemie istnieje kolidujące wydarzenie. Proszę odświeżyć stronę.'
          );
        }

        throw new Error(
          'Błąd pobrania danych kalendarza (/calendar/getSchedule)'
        );
      });
  };

  const graphCall = response => {
    return axios
      .post(
        `https://graph.microsoft.com/v1.0/me/events`,
        // `https://graph.microsoft.com/v1.0/users/368c3aed-61bd-4bab-9ddf-6aefe4de7142/calendar/events`,
        {
          subject: name,
          location: {
            displayName: `Sala ${room.name}`,
            locationType: 'conferenceRoom',
            locationUri: `${room.name}@plej.pl`,
            uniqueId: `${room.name}@plej.pl`,
            uniqueIdType: 'directory'
          },
          start: {
            dateTime: dateFormat(startDate),
            timeZone: 'Central European Standard Time'
          },
          end: {
            dateTime: dateFormat(endDate),
            timeZone: 'Central European Standard Time'
          },
          recurrence,
          organizer: {
            emailAddress: {
              address: currentUser.email,
              name: currentUser.name
            }
          },
          attendees: attendeesArray
        },
        {
          headers: {
            Authorization: `Bearer ${response.token}`,
            responseType: 'application/json'
          }
        }
      )
      .then(res => res.data);
  };

  return currentUser
    .jwtAccessToken()
    .then(getShedule)
    .then(graphCall);
}

export async function removeEvent(
  currentUser,
  event,
  eventsData,
  setEventsData
) {
  let token;
  let eventId;
  const roomName = event.roomId.name.toLowerCase();

  await currentUser.jwtAccessToken().then(data => {
    token = data.accessToken.toString();
  });

  await axios
    .get(
      `https://graph.microsoft.com/v1.0/me/calendar/events?$filter=start/dateTime eq '${event.eventStart}' and end/dateTime eq '${event.eventEnd}'&top=999`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          responseType: 'application/json'
        }
      }
    )
    .then(res => {
      if (!res.data || !res.data.value || res.data.value.length === 0)
        throw new Error('Nie można usunąć. Brak powiązanego wydarzenia.');

      res.data.value.forEach(item => {
        item.attendees.forEach(attendee => {
          if (
            attendee.emailAddress.address.toLowerCase() ===
            `${roomName}@plej.pl`
          ) {
            eventId = item.id;
          }
        });
      });
    })
    .catch(err => console.error(err));

  return axios
    .delete(`https://graph.microsoft.com/v1.0/me/events/${eventId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        responseType: 'application/json'
      }
    })
    .then(res => {
      if (res.status !== 204) {
        console.error(res);
        throw new Error('Błąd podczas usuwania wydarzenia.');
      }

      if (eventsData) {
        const updatedBookingData = eventsData.filter(booking => {
          return booking._id !== event.aadId;
        });

        setEventsData(updatedBookingData);
      }
    });
}

export function updateEventStateOnMake(
  eventsData,
  setEventsData,
  newEvent,
  roomData
) {
  const newBookingEvent = calendarEventsIntoBookings(
    [].concat(newEvent || []),
    roomData,
    eventsData,
    false
  );

  setEventsData(eventsData.concat(newBookingEvent));
}
