import React from 'react';
import * as Styled from './AlertBlock.styles';
import Icon from '../Icon/Icon';

const AlertBlock = ({ children, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Styled.AlertBlock {...rest} role="alert">
    {rest.icon && <Icon icon={rest.icon} removeInlineStyle />}
    <Styled.AlertBlockBox>{children}</Styled.AlertBlockBox>
  </Styled.AlertBlock>
);

export default AlertBlock;
