const momentTimezone = require('moment-timezone');

const dateFormat = date =>
  momentTimezone(date)
    .tz('Europe/Warsaw')
    .format('Y M D');

export const filterAttendanceByDate = (attendanceData, date) => {
  const toCompare = dateFormat(date);

  return attendanceData.filter(
    attendance => dateFormat(new Date(attendance.startDate)) === toCompare
  );
};
