import styled, { css } from 'styled-components';
import Icon from '../Icon/Icon';

const Switcher = styled.button`
  position: relative;
  display: block;
  width: 74px;
  height: 42px;
  padding: 4px;
  margin-bottom: 10px;
  border: 4px solid ${props => props.theme.colors.primary};
  background: #fff;
  border-radius: 20px;
`;

const SwitcherIcon = styled(Icon)`
  &.view,
  &.frequency {
    position: absolute;
    top: 5px;
  }

  &.view {
    right: 6px;
  }

  &.frequency {
    left: 6px;
  }
`;

const SwitcherThumb = styled.span`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 2px;
  bottom: 2px;
  width: calc(50% - 2px);
  left: 2px;
  background: ${props => props.theme.colors.primary};
  border-radius: 16px;
  z-index: 1;
  pointer-events: none;
  transition: left 0.3s;

  ${props =>
    props.toggledView &&
    css`
      left: 50%;
    `}
`;

export { Switcher, SwitcherIcon, SwitcherThumb };
