import styled from 'styled-components';

const MyBookings = styled.article`
  width: 100%;

  h3 {
    margin-bottom: 10px;
  }

  .table-wrapper {
    margin-bottom: 30px;
    overflow-x: auto;
  }
`;

export { MyBookings };
