import React from 'react';
import { Helmet } from 'react-helmet';
import fontLight from '../../assets/fonts/OpenSans-Light.woff2';
import fontRegular from '../../assets/fonts/OpenSans-Regular.woff2';
import fontSemiBold from '../../assets/fonts/OpenSans-SemiBold.woff2';
import fontBold from '../../assets/fonts/OpenSans-Bold.woff2';
import safariPinnedTab from '../../assets/favicon/safari-pinned-tab.svg';
import favicon57 from '../../assets/favicon/apple-touch-icon-57x57.png';
import favicon60 from '../../assets/favicon/apple-touch-icon-60x60.png';
import favicon72 from '../../assets/favicon/apple-touch-icon-72x72.png';
import favicon76 from '../../assets/favicon/apple-touch-icon-76x76.png';
import favicon114 from '../../assets/favicon/apple-touch-icon-114x114.png';
import favicon120 from '../../assets/favicon/apple-touch-icon-120x120.png';
import favicon144 from '../../assets/favicon/apple-touch-icon-144x144.png';
import favicon152 from '../../assets/favicon/apple-touch-icon-152x152.png';
import favicon180 from '../../assets/favicon/apple-touch-icon-180x180.png';
import favicon192 from '../../assets/favicon/android-chrome-192x192.png';
import favicon512 from '../../assets/favicon/android-chrome-512x512.png';
import favicon16 from '../../assets/favicon/favicon-16x16.png';
import favicon32 from '../../assets/favicon/favicon-32x32.png';

export const HtmlHead = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="theme-color" content="#000000" />
      <meta name="robots" content="noindex, nofollow" />
      <title>Office booking</title>
      <link
        rel="preload"
        href={fontLight}
        as="font"
        type="font/woff2"
        crossOrigin
      />
      <link
        rel="preload"
        href={fontRegular}
        as="font"
        type="font/woff2"
        crossOrigin
      />
      <link
        rel="preload"
        href={fontSemiBold}
        as="font"
        type="font/woff2"
        crossOrigin
      />
      <link
        rel="preload"
        href={fontBold}
        as="font"
        type="font/woff2"
        crossOrigin
      />
      <link rel="mask-icon" href={`${safariPinnedTab}`} color="#0E76BC" />
      <link rel="apple-touch-icon" sizes="57x57" href={`${favicon57}`} />
      <link rel="apple-touch-icon" sizes="60x60" href={`${favicon60}`} />
      <link rel="apple-touch-icon" sizes="72x72" href={`${favicon72}`} />
      <link rel="apple-touch-icon" sizes="76x76" href={`${favicon76}`} />
      <link rel="apple-touch-icon" sizes="114x114" href={`${favicon114}`} />
      <link rel="apple-touch-icon" sizes="120x120" href={`${favicon120}`} />
      <link rel="apple-touch-icon" sizes="144x144" href={`${favicon144}`} />
      <link rel="apple-touch-icon" sizes="152x152" href={`${favicon152}`} />
      <link rel="apple-touch-icon" sizes="180x180" href={`${favicon180}`} />
      <link
        rel="icon"
        type="image/png"
        sizes="512x512"
        href={`${favicon512}`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={`${favicon192}`}
      />
      <link rel="icon" type="image/png" sizes="32x32" href={`${favicon32}`} />
      <link rel="icon" type="image/png" sizes="16x16" href={`${favicon16}`} />
    </Helmet>
  );
};
