import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Layout from '../../layouts/Layout/Layout';
import BookingFormWrapper from './BookingFormWrapper';
import BookingModal from '../../components/BookingModal';
import Sidebar from '../../layouts/Sidebar';
import Calendar from '../../components/Calendar';
import Box from '../../layouts/Box';
import BookingTable from './BookingTable';
import { bookingFilter } from '../../helpers/booking';
import Icon from '../../components/Icon/Icon';
import Content from '../../layouts/Content';

const CreateBooking = props => {
  const {
    currentUser,
    navToggler,
    currentRoom,
    currentHour,
    currentDataDate,
    bookingData,
    eventsData,
    setEventsData,
    selectedBooking,
    setBookingData,
    setUserBookings,
    setCalendarDate,
    setSelectedBooking,
    onCloseBooking,
    userBookings,
    calendarDate,
    onDeleteBooking
  } = props;
  return (
    <Layout
      currentUser={currentUser}
      navToggler={navToggler}
      title={`Rezerwacja - ${currentRoom.name}`}
    >
      <Sidebar>
        <Calendar
          calendarDate={calendarDate}
          dateFormat="YYYY-MM-DD"
          timeFormat={false}
          input
          value={moment(calendarDate)}
          renderInput={() => (
            <div className="calendar-aside">
              {moment(calendarDate).format('dddd, [\n] LL')}
            </div>
          )}
          utc={false}
          locale="pl"
          onChange={event => setCalendarDate(event._d)}
        />
        <Box>
          <BookingTable
            bookings={bookingFilter(
              currentRoom.aadId ? eventsData : bookingData,
              currentRoom._id
            )}
            calendarDate={calendarDate}
            setSelectedBooking={setSelectedBooking}
            currentUser={currentUser}
          />
        </Box>
      </Sidebar>
      <Content>
        <Link to="/" className="back-to-main">
          <Icon icon="chevron-left" size={12} />
          Wróć do głównej
        </Link>
        <Box>
          <BookingFormWrapper
            currentUser={currentUser}
            currentRoom={currentRoom}
            currentHour={currentHour}
            currentDataDate={currentDataDate}
            bookingData={bookingData}
            setBookingData={setBookingData}
            eventsData={eventsData}
            setEventsData={setEventsData}
            userBookings={userBookings}
            setUserBookings={setUserBookings}
            calendarDate={calendarDate}
            setCalendarDate={setCalendarDate}
            setSelectedBooking={setSelectedBooking}
          />
        </Box>
      </Content>
      <BookingModal
        selectedBooking={selectedBooking}
        onCloseBooking={onCloseBooking}
        onDeleteBooking={onDeleteBooking}
        currentDataDate={currentDataDate}
        currentUser={currentUser}
      />
    </Layout>
  );
};

export default CreateBooking;
