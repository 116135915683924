import { NavLink as NativeNavLink } from 'react-router-dom';
import styled from 'styled-components';

const ReservationTypeNav = styled.nav`
  display: inline-flex;
  padding: 2px;
  background: #e7e5e1;
  border-radius: ${props => props.theme.borderRadius};
  margin-left: auto;
`;

const ReservationTypeNavLink = styled(NativeNavLink)`
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  line-height: 1;
  color: ${props => props.theme.colors.navLink};
  border-radius: ${props => props.theme.borderRadius};

  &.active {
    color: #41403e;
    background: #fff;
    cursor: default;
  }

  .nav-icon {
    margin-right: 6px;
  }

  &:not(.active) {
    &:hover,
    &:focus {
      color: #41403e;
    }
  }
`;

export { ReservationTypeNav, ReservationTypeNavLink };
