import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Icon from '../../components/Icon/Icon';

const ErrorPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
`;

const ErrorPageIcon = styled(Icon)`
  margin-bottom: 24px;
  fill: currentColor;
  display: inline-block;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 150px;
    height: 150px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    width: 200px;
    height: 200px;
  }
`;

const ErrorPageHead = styled.h1`
  margin-bottom: 16px;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    font-size: 30px;
  }
`;

const ErrorPageLink = styled(Link)`
  display: block;

  svg {
    margin-right: 5px;
  }
`;

export { ErrorPage, ErrorPageIcon, ErrorPageHead, ErrorPageLink };
