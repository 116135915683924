import React, { useEffect, useRef } from 'react';
import handleViewport from 'react-in-viewport';
import Table from '../../../../components/Typography/Table';
import Icon from '../../../../components/Icon/Icon';

const SlideInfo = props => {
  const { inViewport, forwardedRef } = props;
  const activeClass = inViewport ? 'active' : '';
  return (
    <span
      className={`slide-info viewport-block ${activeClass}`}
      ref={forwardedRef}
    >
      <Icon icon="slide" size={36} className="nav-user-icon" />
    </span>
  );
};

const ViewportBlock = handleViewport(SlideInfo);

const RoomTable = props => {
  const { children } = props;
  const tableWrap = useRef(null);

  useEffect(() => {
    if (
      tableWrap.current.offsetWidth < tableWrap.current.lastChild.offsetWidth
    ) {
      tableWrap.current.className += ' scrolled';
    }
  }, []);

  return (
    <div className="table-wrapper" ref={tableWrap}>
      <ViewportBlock />

      <Table className="table">
        <thead>
          <tr className="table__row table__row--subheader">
            <th
              scope="col"
              className="table__cell--header table__cell--align-left"
            >
              Nazwa
            </th>
            <th scope="col" className="table__cell--header">
              8:00
            </th>
            <th scope="col" className="table__cell--header">
              9:00
            </th>
            <th scope="col" className="table__cell--header">
              10:00
            </th>
            <th scope="col" className="table__cell--header">
              11:00
            </th>
            <th scope="col" className="table__cell--header">
              12:00
            </th>
            <th scope="col" className="table__cell--header">
              13:00
            </th>
            <th scope="col" className="table__cell--header">
              14:00
            </th>
            <th scope="col" className="table__cell--header">
              15:00
            </th>
            <th scope="col" className="table__cell--header">
              16:00
            </th>
            <th scope="col" className="table__cell--header">
              17:00
            </th>
            <th scope="col" className="table__cell--header">
              18:00
            </th>
            <th scope="col" className="table__cell--header">
              19:00
            </th>
            <th scope="col" className="table__cell--header">
              20:00
            </th>
          </tr>
        </thead>
        <tbody className="table__body">{children}</tbody>
      </Table>
    </div>
  );
};

export default RoomTable;
