import momentTimezone from 'moment-timezone';
import api from './init';
import { getCurrentToken } from '../helpers/users';

export function attendanceList(data) {
  return api
    .get('/presences', {
      params: data,
      headers: { Authorization: `Bearer ${getCurrentToken()}` }
    })
    .then(res => res.data);
}

const dateUTC = dataArray =>
  momentTimezone(dataArray)
    .tz('Europe/Warsaw')
    .toDate()
    .setHours(12, 0, 0, 0);

export function reportAttendance(currentUser, startPresence, endPresence) {
  const currentDate = new Date();
  currentDate.setHours(1);
  const presenceStart = dateUTC(startPresence);
  const presenceEnd = endPresence ? dateUTC(endPresence) : endPresence;

  if (presenceEnd && presenceStart > presenceEnd)
    throw new Error(
      'Data zakończenia musi być późniejsza niż data rozpoczęcia.'
    );

  if (presenceStart < currentDate.getTime())
    throw new Error('Data rozpoczęcia musi być datą w przyszłości.');

  return api.put(
    `/presence`,
    {
      user: currentUser.id,
      name: currentUser.name,
      email: currentUser.email,
      businessUnit: currentUser.department,
      startDate: presenceStart,
      endDate: presenceEnd
    },
    {
      headers: { Authorization: `Bearer ${getCurrentToken()}` }
    }
  );
}

export function deleteAttendance(id, data) {
  return api
    .delete(`/presence/${id}`, {
      data,
      headers: { Authorization: `Bearer ${getCurrentToken()}` }
    })
    .then(res => res);
}
