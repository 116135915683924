import React from 'react';
import { Link } from 'react-router-dom';
import { dailyBookings, bookingArray } from '../../../../helpers/rooms';
import { userUnitKey } from '../../../../helpers/users';
import LabelTooltip from '../../../../components/LabelTooltip/LabelTooltip';
import Icon from '../../../../components/Icon/Icon';

// Accept the 24 hour dayHours array as the day's booking data for a room
const rowMapper = (dayHours, props) => {
  const tableRow = [];

  // Loop through each hour from 8:00 to 21:00
  for (let i = 8; i < 21; i += 1) {
    // Extract the corresponding data from the 24 hour array
    const bookingData = dayHours[i];

    if (props.room.disabled === true) {
      tableRow.push(<td className="table__cell--disabled" key={i} />);
    } else if (typeof bookingData === 'number') {
      tableRow.push(
        <td className="table__cell--available" key={i}>
          <Link
            title="Zarezerwuj ten termin"
            to="/createbooking"
            onClick={() => {
              props.onSetRoom(props.room._id);
              props.setCurrentHour(`${i}:00`);
            }}
            className="table__link--available"
          />
        </td>
      );

      // If the data is an array, there are two booking objects
    } else if (Array.isArray(bookingData)) {
      // Determine which of the two bookings comes first and second
      const firstBookingData = bookingData[0].firstHalfHour
        ? bookingData[0]
        : bookingData[1];
      const secondBookingData = bookingData[0].secondHalfHour
        ? bookingData[0]
        : bookingData[1];

      tableRow.push(
        <td className="table__cell" key={bookingData[0]._id}>
          <button
            title={`Zarezerwowany przez: ${firstBookingData.name}`}
            aria-label="Rezerwacja"
            onClick={() => props.setSelectedBooking(firstBookingData)}
            type="button"
            className={`
              table__cell--booked table__cell--half table__cell--first-half-hour
              table__cell--${userUnitKey(firstBookingData.businessUnit)}
              ${firstBookingData.duration === 0.5 ? 'table__cell--start' : ''}
              ${
                firstBookingData.email.toLowerCase() ===
                props.currentUser.email.toLowerCase()
                  ? 'my__booking'
                  : ''
              }
              ${firstBookingData.status === 1 ? 'status--pending' : ''}
            `}
          />
          <button
            title={`Zarezerwowany przez: ${secondBookingData.name}`}
            aria-label="Rezerwacja"
            onClick={() => props.setSelectedBooking(secondBookingData)}
            type="button"
            className={`
              table__cell--booked table__cell--half table__cell--second-half-hour
              table__cell--${userUnitKey(secondBookingData.businessUnit)}
              ${secondBookingData.duration === 0.5 ? 'table__cell--end' : ''}
              ${
                secondBookingData.email.toLowerCase() ===
                props.currentUser.email.toLowerCase()
                  ? 'my__booking'
                  : ''
              }
              ${secondBookingData.status === 1 ? 'status--pending' : ''}
            `}
          />
        </td>
      );

      // If there is a booking object, add a <td> element with custom class name to enable stlying
    } else {
      tableRow.push(
        <td className="table__cell" key={i}>
          <button
            title={`Zarezerwowany przez: ${bookingData.name}. Cel spotkania: ${bookingData.purpose}`}
            aria-label="Rezerwacja"
            onClick={() => props.setSelectedBooking(bookingData)}
            type="button"
            className={`table__cell--booked table__cell--${userUnitKey(
              bookingData.businessUnit
            )}
              ${
                bookingData.firstHalfHour
                  ? 'table__cell--half table__cell--first-half-hour'
                  : ''
              }
              ${
                bookingData.secondHalfHour
                  ? 'table__cell--half table__cell--second-half-hour'
                  : ''
              }
              ${i === bookingData.startHour ? 'table__cell--start' : ''}
              ${
                i === bookingData.startHour + bookingData.duration - 1
                  ? 'table__cell--end'
                  : ''
              }
              ${
                bookingData.email.toLowerCase() ===
                props.currentUser.email.toLowerCase()
                  ? 'my__booking'
                  : ''
              }
              ${bookingData.status === 1 ? 'status--pending' : ''}
            `}
          />
          {(bookingData.firstHalfHour || bookingData.secondHalfHour) && (
            <Link
              title="Zarezerwuj ten termin"
              to="/createbooking"
              onClick={() => {
                props.onSetRoom(props.room._id);
                props.setCurrentHour(
                  i + (bookingData.secondHalfHour ? ':00' : ':30')
                );
              }}
              className={`
              table__link--available
              ${
                bookingData.secondHalfHour
                  ? 'table__cell--half table__cell--first-half-hour'
                  : ''
              }
              ${
                bookingData.firstHalfHour
                  ? 'table__cell--half table__cell--second-half-hour'
                  : ''
              }
            `}
            />
          )}
        </td>
      );
    }
  }
  return tableRow;
};

const RoomRow = props => {
  const { room, onSetRoom, calendarDate, bookings } = props;

  return (
    <tr
      className={`table__row${
        room.disabled === true ? ' table__row--disabled' : ''
      }`}
    >
      <th scope="row" className="table__cell--header">
        <span className="table__cell--header-content">
          {room.disabled !== true && (
            <>
              <Link
                to="/createbooking"
                onClick={() => onSetRoom(room._id)}
                className="room-name table__link"
              >
                {room.name}
              </Link>
              {parseInt(room.floor, 10) === 1 && (
                <span className="table__room-capacity">
                  {` (${room.capacity}`}
                  <Icon icon="team" size={17} className="team-icon" />)
                </span>
              )}
            </>
          )}
          {room.disabled === true && (
            <span className="room-name">{room.name}</span>
          )}
          <ul className="table__data--asset-list">
            {room.features.map(asset => (
              <li key={asset.key} className="table__data--asset">
                <LabelTooltip asset={asset}>
                  <Icon icon={asset.key} size={25} />
                </LabelTooltip>
              </li>
            ))}
          </ul>
        </span>
      </th>
      {rowMapper(bookingArray(dailyBookings(calendarDate, bookings)), props)}
    </tr>
  );
};

export default RoomRow;
