import React from 'react';
import { Link } from 'react-router-dom';
import * as Styled from './HotdesksView.styles';
import BookingTooltip from '../BookingTooltip';
import { bookingFilter } from '../../../../helpers/booking';

const Desk = props => {
  const {
    isDisabled,
    desk,
    bookings,
    calendarDate,
    currentUser,
    onSetRoom,
    setCurrentHour,
    setSelectedBooking
  } = props;

  return isDisabled ? (
    <div className={`desk desk-${desk.name.slice(-2).trim()} disabled`}>
      <span className="desk-label">{desk.name.slice(-2).trim()}</span>
    </div>
  ) : (
    <div
      className={`desk desk-${desk.name.slice(-2).trim()} ${
        desk.assets[0] ? 'mac' : ''
      }`}
    >
      <Link
        title="Zarezerwuj biurko"
        to="/createbooking"
        onClick={() => {
          onSetRoom(desk._id);
        }}
        className="desk-content"
      />

      <span className="desk-label">{desk.name.slice(-2).trim()}</span>

      <BookingTooltip
        room={desk}
        bookings={bookings}
        setSelectedBooking={setSelectedBooking}
        calendarDate={calendarDate}
        onSetRoom={onSetRoom}
        setCurrentHour={setCurrentHour}
        currentUser={currentUser}
      />
    </div>
  );
};

const HotdesksView = props => {
  const {
    rooms,
    bookings,
    calendarDate,
    currentUser,
    onSetRoom,
    setCurrentHour,
    setSelectedBooking
  } = props;

  return (
    <Styled.HotdesksView>
      <div
        className={`room ${
          rooms.every(room => room.assets[0] === 'mac30') ? 'filtered-mac' : ''
        }`}
      >
        {rooms.map(desk => {
          return (
            <Desk
              name={desk.name}
              key={desk.name}
              isDisabled={desk.disabled}
              bookings={bookingFilter(bookings, desk._id)}
              desk={desk}
              setSelectedBooking={setSelectedBooking}
              calendarDate={calendarDate}
              onSetRoom={onSetRoom}
              setCurrentHour={setCurrentHour}
              currentUser={currentUser}
            />
          );
        })}
      </div>
    </Styled.HotdesksView>
  );
};

export default HotdesksView;
