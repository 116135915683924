import styled from 'styled-components';

const LabelTooltip = styled.div`
  cursor: help;
  position: relative;
  margin-left: 10px;

  &:after,
  &:before {
    position: absolute;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    left: 50%;
    pointer-events: none;
    transition: 0.2s;
    will-change: transform;
  }

  &:before {
    content: attr(data-label-info);
    bottom: calc(100% - 2px);
    min-width: 50px;
    padding: 3px 8px;
    background-color: ${props => props.theme.colors.primary};
    border-radius: ${props => props.theme.borderRadius};
    transform: translate(-50%, -5px) scale(0.5);
    color: ${props => props.theme.colors.white};
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }

  &:after {
    content: '';
    bottom: 100%;
    border-style: solid;
    border-width: 5px 5px 0px 5px;
    border-color: ${props => props.theme.colors.primary} transparent transparent
      transparent;
    transition-duration: 0s;
    transform-origin: top;
    transform: translateX(-50%) scaleY(0);
  }

  &:hover,
  &:focus {
    &:after,
    &:before {
      visibility: visible;
      opacity: 1;
    }

    &:before {
      transition-delay: 0.3s;
      transform: translate(-50%, -5px) scale(1);
    }

    &:after {
      transition-delay: 0.5s;
      transition-duration: 0.2s;
      transform: translateX(-50%) scaleY(1);
    }
  }

  > svg {
    vertical-align: bottom;
  }
`;

export { LabelTooltip };
