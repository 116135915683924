import api from './init';
import { getCurrentToken } from '../helpers/users';

export function getRoomsList() {
  return api
    .get('/rooms', {
      headers: { Authorization: `Bearer ${getCurrentToken()}` }
    })
    .then(res => res.data);
}
