import React from 'react';
import * as Styled from './Box.styles';

const Box = props => {
  const { className, header, children } = props;

  return (
    <Styled.Box className={className}>
      {header && <h3>{header}</h3>}
      {children}
    </Styled.Box>
  );
};

export default Box;
