import React from 'react';
import moment from 'moment';
import 'moment/locale/pl';
import ColumnCell from './ColumnCell';
import Table from '../../../components/Typography/Table';

const BookingTable = props => {
  const {
    currentUser,
    calendarDate,
    bookings,
    onSetRoom,
    setCurrentHour,
    room,
    setSelectedBooking
  } = props;
  const rows = [];

  for (let i = 8; i < 21; i += 1) {
    rows.push(
      <tr className="table__row--full" key={i}>
        <th scope="row">{i}:00</th>
        <ColumnCell
          currentUser={currentUser}
          room={room}
          calendarDate={calendarDate}
          bookings={bookings}
          onSetRoom={onSetRoom}
          setCurrentHour={setCurrentHour}
          setSelectedBooking={setSelectedBooking}
          hour={i}
        />
      </tr>
    );
  }

  return (
    <Table className="booking">
      <caption>
        {moment(calendarDate)
          .locale('pl')
          .format('D MMMM YYYY')}
      </caption>
      <thead>
        <tr className="table__row table__row--subheader">
          <th className="table__cell--header table__cell--header-time">
            <span className="text">Godz.</span>
          </th>
          <th className="table__cell--header table__cell--header-availability">
            <span className="text">Dostępność</span>
          </th>
        </tr>
      </thead>
      <tbody className="table__body--booking">{rows}</tbody>
    </Table>
  );
};

export default BookingTable;
