import styled from 'styled-components';

const Header = styled.header`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 20px;
  background-color: ${props => props.theme.colors.white};
`;

export { Header };
