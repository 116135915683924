import styled from 'styled-components';

const Departments = styled.section`
  .key {
    &__item {
      display: inline-block;
      margin-right: 7px;

      > div {
        margin-left: 0;
      }
    }

    &__color {
      display: inline-block;
      width: 24px;
      height: 24px;
      border: 1px solid ${props => props.theme.colors.darkenGray};
      border-radius: 50%;

      // Colors define
      &--finance {
        background: ${props => props.theme.department.finanse};
      }
      &--hr {
        background: ${props => props.theme.department.hr};
      }
      &--legaloperations {
        background: ${props => props.theme.department.legaloperations};
      }
      &--kreacjaeventowa {
        background: ${props => props.theme.department.kreacjaeventowa};
      }
      &--events {
        background: ${props => props.theme.department.eventy};
      }
      &--clientservice {
        background: ${props => props.theme.department.clientservice};
      }
      &--strategy {
        background: ${props => props.theme.department.strategia};
      }
      &--kreacja,
      &--creation,
      &--creationzdalnie {
        background: ${props => props.theme.department.kreacja};
      }
      &--socialmedia {
        background: ${props => props.theme.department.socialmedia};
      }
      &--development {
        background: ${props => props.theme.department.development};
      }
      &--pmo {
        background: ${props => props.theme.department.pmo};
      }
      &--uxui,
      &--ux,
      &--ui {
        background: ${props => props.theme.department.uxui};
      }
      &--publicrelations {
        background: ${props => props.theme.department.publicrelations};
      }
      &--businessdevelopment {
        background: ${props => props.theme.department.businessdevelopment};
      }
      &--zarzad,
      &--boa {
        background: ${props => props.theme.department.zarzad};
      }
      &--inne {
        background: ${props => props.theme.department.other};
      }
    }
  }
`;

export { Departments };
