import styled from 'styled-components';

const AttendanceList = styled.article`
  width: 100%;

  table {
    .attendance-action {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .my-attendance {
      background-color: ${props => props.theme.colors.primaryLight} !important;
    }
    button {
      margin: 0 5px;
    }
  }
`;

export { AttendanceList };
