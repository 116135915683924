import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import styled from 'styled-components';

const ExcelButton = styled(ReactHTMLTableToExcel)`
  display: inline-block;
  font-weight: 600;
  padding: 10px 15px;
  text-transform: uppercase;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};

  &:hover,
  &:focus {
    background: ${props => props.theme.colors.secondary};
  }

  margin-top: 20px;
`;

export { ExcelButton };
