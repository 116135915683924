import styled from 'styled-components';

const Content = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 100%;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: calc(70% - 20px);
    z-index: 1;
  }

  .back-to-main {
    display: inline-block;
    margin-bottom: 10px;

    svg {
      margin-right: 5px;
    }
  }
`;

export { Content };
