import React from 'react';
import IcoMoon from 'react-icomoon';

const iconSet = require('../../assets/icons/selection.json');

const Icon = ({ ...props }) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <IcoMoon iconSet={iconSet} {...props} />;
};

export default Icon;
