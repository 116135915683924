import React, { useEffect } from 'react';
import MyBookingsRow from './MyBookingsTable/MyBookingsRow';
import MyBookingsTable from './MyBookingsTable';
import Layout from '../../layouts/Layout/Layout';
import { listBookings } from '../../api/booking';
import * as Styled from './MyBookings.styles';

const MyBookings = props => {
  const {
    currentUser,
    bookingData,
    currentDataDate,
    navToggler,
    onDeleteBooking,
    setUserBookings,
    userBookings
  } = props;
  const filterBookings = (bookings, time) => {
    const myBookings = [];
    const oldBookings = [];
    const userId = currentUser.id;

    // Loop through all the rooms
    bookings.forEach(booking => {
      // Loop through all the bookings in 'room'
      if (booking.user === userId) {
        if (booking.bookingEnd >= time) {
          // Push all today and future bookings where the current userId is equal to the booking's userId into myBookings
          myBookings.push(booking);
        } else {
          oldBookings.push(booking);
        }
      }
    });

    setUserBookings({ current: myBookings, old: oldBookings });
  };

  const checkCurrentBookings = () => {
    if (userBookings.current.length === 0) return;

    const updatedUserBookings = { ...userBookings };
    updatedUserBookings.current = [];

    userBookings.current.forEach(booking => {
      // Loop through all the bookings in 'room'
      if (booking.bookingEnd >= currentDataDate) {
        // Push all today and future bookings where the current userId is equal to the booking's userId into myBookings
        updatedUserBookings.current.push(booking);
      } else {
        updatedUserBookings.old.push(booking);
      }

      setUserBookings(updatedUserBookings);
    });
  };

  useEffect(() => {
    if (userBookings) {
      checkCurrentBookings();
    } else if (bookingData) {
      // all bookings already loaded -> now we can filter them
      filterBookings(bookingData, currentDataDate);
    } else {
      // load only my bookings
      listBookings({ user: currentUser.id })
        .then(response => {
          filterBookings(response.bookings, response.currentDate);
        })
        .catch(error => console.error('Error loading booking data', error));
    }
  }, []);

  return (
    <Layout
      currentUser={currentUser}
      navToggler={navToggler}
      title="Zarezerwowane zasoby"
      contentClass="my-bookings"
    >
      <Styled.MyBookings>
        <div>
          <h3>Aktywne rezerwacje</h3>
          {userBookings &&
          userBookings.current &&
          userBookings.current.length ? (
            <MyBookingsTable>
              {Object.keys(
                userBookings.current.sort((a, b) =>
                  a.bookingStart.localeCompare(b.bookingStart)
                )
              ).map(key => (
                <MyBookingsRow
                  key={key}
                  bookingData={userBookings.current[key]}
                  onDeleteBooking={onDeleteBooking}
                />
              ))}
            </MyBookingsTable>
          ) : (
            <p>Nie masz żadnych aktywnych rezerwacji</p>
          )}
        </div>
        <div>
          <h3>Przeszłe rezerwacje z ostatnich 30 dni</h3>
          {userBookings && userBookings.old && userBookings.old.length ? (
            <MyBookingsTable bookingStatus="old">
              {Object.keys(userBookings.old).map(key => (
                <MyBookingsRow
                  key={key}
                  bookingData={userBookings.old[key]}
                  onDeleteBooking={null}
                />
              ))}
            </MyBookingsTable>
          ) : (
            <p>Nie masz żadnych przeszłych rezerwacji</p>
          )}
        </div>
      </Styled.MyBookings>
    </Layout>
  );
};

export default MyBookings;
