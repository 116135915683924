import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const clientId = process.env.REACT_APP_ACTIVE_DIRECTORY_CLIENT_ID;
const tenantId = process.env.REACT_APP_ACTIVE_DIRECTORY_TENANT_ID;
const { origin } = window.location;

// Msal Configurations
const config = {
  auth: {
    authority: `https://login.microsoftonline.com/${tenantId}`,
    clientId,
    postLogoutRedirectUri: origin,
    redirectUri: origin,
    validateAuthority: true,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
};

// Authentication Parameters
export const authenticationParameters = {
  scopes: ['User.Read', 'Calendars.ReadWrite.Shared']
};
export const authenticationParametersGraph = {
  scopes: ['openid']
};

// Options
export const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: origin
};
export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
