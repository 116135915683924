import styled from 'styled-components';

const Filters = styled.div`
  display: inline-flex;

  //@media (max-width: 320px) {
  //  display: block;
  //}
  //
  //@media (min-width: 360px) {
  //  position: absolute;
  //  top: 10px;
  //  right: 20px;
  //}

  .filters-group-box {
    margin-bottom: 20px;
  }

  .filters-group-head {
    margin-bottom: 16px;
  }

  .filters-form {
    display: flex;
  }
`;

const InputRadio = styled.div`
  line-height: 1;

  &.input-radio-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .input-radio {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;
    appearance: none;
    color-adjust: exact;
    border: 2px solid ${props => props.theme.colors.darkenGray};
    background-color: ${props => props.theme.colors.white};
    transition: background-color 0.15s ease-in-out,
      background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    &:after,
    &:before {
      content: '';
      position: absolute;
      border-radius: 50%;
      background-color: ${props => props.theme.colors.white};
    }

    &:before {
      width: 16px;
      height: 16px;
    }

    &:after {
      width: 10px;
      height: 10px;
    }

    &:checked {
      border-color: ${props => props.theme.colors.primary};

      &:before {
        opacity: 0.16;
        transform: scale(1);
        box-shadow: 0 0 0 5px ${props => props.theme.colors.primary};
        transition: box-shadow 0.2s, transform 0.2s;
      }

      &:after {
        background-color: ${props => props.theme.colors.primary};
      }
    }

    &:focus {
      outline: none;

      &:before {
        opacity: 0.6;
      }
    }
  }
`;

const InputCheckbox = styled.div`
  line-height: 1;

  &.input-checkbox-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .input-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    appearance: none;
    color-adjust: exact;
    border: 2px solid ${props => props.theme.colors.darkenGray};
    background-color: ${props => props.theme.colors.white};
    transition: background-color 0.15s ease-in-out,
      background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    &:after,
    &:before {
      content: '';
      position: absolute;
      background-color: ${props => props.theme.colors.white};
    }

    &:before {
      width: 16px;
      height: 16px;
    }

    &:after {
      width: 10px;
      height: 10px;
    }

    &:checked {
      border-color: ${props => props.theme.colors.primary};

      &:before {
        opacity: 0.16;
        transform: scale(1);
        box-shadow: 0 0 0 5px ${props => props.theme.colors.primary};
        transition: box-shadow 0.2s, transform 0.2s;
      }

      &:after {
        background-color: ${props => props.theme.colors.primary};
      }
    }

    &:focus {
      outline: none;

      &:before {
        opacity: 0.6;
      }
    }
  }
`;

export { Filters, InputRadio, InputCheckbox };
