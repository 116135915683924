import styled from 'styled-components';
import { ReactMultiEmail } from 'react-multi-email';

const MultiEmail = styled(ReactMultiEmail)`
  position: relative;
  flex: 1 0 auto;
  width: 100%;
  display: block;
  padding: 16px;
  appearance: none;
  font-size: ${props => props.theme.fontSize};
  border: 2px solid ${props => props.theme.colors.defaultGray};
  border-radius: ${props => props.theme.borderRadiusInner};

  input {
    border: 0;
    outline: 0;
    min-width: 200px;
  }

  [data-placeholder] {
    position: absolute;
    top: 14px;
    left: 12px;
    padding: 2px 4px;
    pointer-events: none;
    background-color: transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }

  [data-tag] {
    display: inline-block;

    button {
      position: relative;
      display: inline-block;
      padding: 5px 28px 5px 8px;
      margin: 4px;
      background-color: ${props => props.theme.colors.primary};
      color: #fff;
      border-radius: ${props => props.theme.borderRadiusInner};

      &:after {
        content: '×';
        font-size: 18px;
        line-height: 1;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-45%);
      }

      &:hover {
        background-color: ${props => props.theme.colors.alert};
      }
    }
  }

  &.focused {
    border-color: ${props => props.theme.colors.primary};
  }

  &:not(.empty) {
    [data-placeholder] {
      transform: scale(0.85) translateY(-30px);
      background-color: #fff;
    }
  }
`;

export { MultiEmail };
