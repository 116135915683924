import React from 'react';
import * as Styled from './Footer.styles';
import packageJson from '../../../package.json';

const Footer = () => (
  <Styled.Footer>
    &copy; booking.plej.pl | wersja: {packageJson.version}
  </Styled.Footer>
);

export default Footer;
