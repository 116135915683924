import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAlert } from 'react-alert';
import { useIdleTimer } from 'react-idle-timer';
import 'moment/locale/pl';
import OnRouteChange from './layouts/OnRouteChange';
import { HtmlHead } from './layouts/HtmlHead/HtmlHead';
import { getAccessToken } from './api/user';
import { removeEvent } from './api/calendar';
import { setUserSetting } from './helpers/users';
import { deleteBooking, updateBookingStateOnDelete } from './api/booking';
import { roles, userRoles } from './config/roles';
import Navigation from './layouts/Navigation';
import MainWrapper from './layouts/MainWrapper';
import Header from './layouts/Header';
import Main from './layouts/Main';
import PageWrapper from './layouts/PageWrapper';
import Footer from './layouts/Footer';
import Spinner from './components/Spinner';
import Routes from './pages/Routes';

// TODO - createbooking - remove currentRoom required and add input to select room
// TODO - createbooking - add multiple choice input on invite people

const App = ({ authProvider }) => {
  const [loading, setLoading] = useState(true);
  const [roomData, setRoomData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [bookingData, setBookingData] = useState(null);
  const [eventsData, setEventsData] = useState(null);
  const [userBookings, setUserBookings] = useState(null);
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [currentDataDate, setCurrentDataDate] = useState(null);
  const [currentAttendanceDate, setCurrentAttendanceDate] = useState(null);
  const [toggledView, setToggledView] = useState(false);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [currentHour, setCurrentHour] = useState(null);
  const [currentUser, setCurrentUser] = useState({
    token: authProvider.account.idToken,
    name: authProvider.account.name,
    email: authProvider.account.userName,
    id: authProvider.account.accountIdentifier,
    role: roles.worker,
    department: 'Inne',
    jwtAccessToken: authProvider.getAccessToken,
    logout: authProvider.logout
  });
  const alert = useAlert();
  const mainWrapperRef = useRef(null);
  const [navOpened, setNavOpened] = useState(false);

  const navToggler = {
    navOpened,
    toggle: () => setNavOpened(!navOpened),
    close: () => setNavOpened(false)
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15, // 15 min
    onIdle: () => authProvider.logout(),
    debounce: 500
  });

  const getLocalToken = () => {
    getAccessToken(authProvider.account)
      .then(response => {
        setUserSetting('token', response.token);
        setLoading(false);
      })
      .catch(error => alert.error(error, { timeout: 0 }));
  };

  // set user settings depends on his profile in local storage
  const getUserSettings = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));

    if (!profile) return;

    if (profile.toggledView !== undefined) setToggledView(profile.toggledView);
  };

  const getDepartment = () => {
    const updatedUser = { ...currentUser };

    if (userRoles.admin.includes(updatedUser.email.toLowerCase()))
      updatedUser.role = roles.admin;
    if (userRoles.ceo.includes(updatedUser.email.toLowerCase()))
      updatedUser.role = roles.ceo;

    currentUser.jwtAccessToken().then(data => {
      axios
        .get(`https://graph.microsoft.com/v1.0/me?$select=department`, {
          headers: { Authorization: `Bearer ${data.accessToken.toString()}` }
        })
        .then(res => {
          const { department } = res.data;

          updatedUser.department = department || 'Inne';

          setCurrentUser(updatedUser);
        });
    });
  };

  // eslint-disable-next-line no-unused-vars
  const getUsersList = () => {
    currentUser.jwtAccessToken().then(data => {
      axios
        .get(`https://graph.microsoft.com/v1.0/users?$top=999`, {
          headers: { Authorization: `Bearer ${data.accessToken.toString()}` }
        })
        .then(res => {
          console.log(res.data.value);
        });
    });
  };

  // eslint-disable-next-line no-unused-vars
  const getPermissions = () => {
    currentUser.jwtAccessToken().then(data => {
      axios
        .get(`https://graph.microsoft.com/v1.0/me/oauth2PermissionGrants`, {
          headers: { Authorization: `Bearer ${data.accessToken.toString()}` }
        })
        .then(res => {
          if (!res.data.value.length) return;
          let temp = [];

          res.data.value.forEach(item => {
            temp = temp.concat(item.scope.split(' '));
          });

          console.log([...new Set(temp)]);
        });
    });
  };

  const updateUser = () => {
    getLocalToken();
    getUserSettings();
    getPermissions();
    getDepartment();
    // getPermissions();
    // getUsersList();
  };

  useEffect(updateUser, []);

  const onCloseBooking = () => setSelectedBooking(null);

  // Deletes a booking from the database and updates the React state
  const onDeleteBooking = booking => {
    if (!booking.aadId)
      deleteBooking(booking._id, { currentUser })
        .then(response => {
          updateBookingStateOnDelete(
            setBookingData,
            bookingData,
            setUserBookings,
            userBookings,
            booking._id
          );

          if (response && !response.status && attendanceData.length) {
            // if is presence related with deleted booking and attendanceData is set
            const newAttendanceData = attendanceData.filter(
              // eslint-disable-next-line no-underscore-dangle
              attendance => attendance._id !== response._id
            );
            setAttendanceData(newAttendanceData);
            alert.info('Usunięto również obecność powiązaną z tą rezerwacją.');
          }
        })
        .catch(error => console.error(error.message));

    if (booking.aadId)
      removeEvent(currentUser, booking, eventsData, setEventsData)
        .then()
        .catch(error => {
          alert.info(error.message);
        });
  };

  // eslint-disable-next-line no-underscore-dangle
  const setRoom = id => setCurrentRoom(roomData.find(room => room._id === id));

  return (
    <div id="app" className="App">
      <HtmlHead />
      <OnRouteChange navToggler={navToggler} mainWrapperRef={mainWrapperRef} />

      <PageWrapper>
        <Navigation currentUser={currentUser} navToggler={navToggler} />
        <MainWrapper
          navToggler={navToggler}
          closeClick={navToggler.close}
          mainWrapperRef={mainWrapperRef}
        >
          <Header navToggler={navToggler} />
          <Main>
            {loading && <Spinner />}
            {!loading && (
              <Routes
                // Current user
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                // Toggle view
                navToggler={navToggler}
                toggledView={toggledView}
                setToggledView={setToggledView}
                // Calendar
                calendarDate={calendarDate}
                setCalendarDate={setCalendarDate}
                // Api fetch date
                currentDataDate={currentDataDate}
                setCurrentDataDate={setCurrentDataDate}
                // Hours
                setCurrentHour={setCurrentHour}
                // Rooms list
                roomData={roomData}
                setRoomData={setRoomData}
                onSetRoom={setRoom}
                currentRoom={currentRoom}
                currentHour={currentHour}
                // Booking
                bookingData={bookingData}
                setBookingData={setBookingData}
                setSelectedBooking={setSelectedBooking}
                userBookings={userBookings}
                setUserBookings={setUserBookings}
                eventsData={eventsData}
                setEventsData={setEventsData}
                // Booking modal
                selectedBooking={selectedBooking}
                onCloseBooking={onCloseBooking}
                onDeleteBooking={onDeleteBooking}
                // Attendance
                setAttendanceData={setAttendanceData}
                attendanceData={attendanceData}
                // Api fetch date attendance
                setCurrentAttendanceDate={setCurrentAttendanceDate}
                currentAttendanceDate={currentAttendanceDate}
              />
            )}
          </Main>
          <Footer />
        </MainWrapper>
      </PageWrapper>
    </div>
  );
};

export default App;
